import { MetaField } from '../../types/metas';
import get from 'lodash/get';

export const CampaignFilter: any = {
  getFieldByPath(applicationPath: string): MetaField {
    return get(CampaignFilter, Object.keys(CampaignFilter).find(fieldPath =>
      CampaignFilter[fieldPath].path === applicationPath.toLowerCase()),
    );
  },
  'civilStatus': {
    'path': 'civilstatus',
    'label': 'Estado civil ',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': 'single', 'description': 'Solteiro(a)', 'products': '', 'filter': '' },
        { 'code': 'married', 'description': 'Casado(a)', 'products': '', 'filter': '' },
        { 'code': 'divorced', 'description': 'Divorciado(a)', 'products': '', 'filter': '' },
        { 'code': 'widowed', 'description': 'Viúvo(a)', 'products': '', 'filter': '' },
      ],
    },
    'isMandatory': () => false,
  },
  'stateOfBirth': {
    'path': 'stateofbirth',
    'label': 'Estado de nascimento',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { code: 'AC', description: 'AC' },
        { code: 'AL', description: 'AL' },
        { code: 'AP', description: 'AP' },
        { code: 'AM', description: 'AM' },
        { code: 'BA', description: 'BA' },
        { code: 'CE', description: 'CE' },
        { code: 'DF', description: 'DF' },
        { code: 'ES', description: 'ES' },
        { code: 'GO', description: 'GO' },
        { code: 'MA', description: 'MA' },
        { code: 'MT', description: 'MT' },
        { code: 'MS', description: 'MS' },
        { code: 'MG', description: 'MG' },
        { code: 'PA', description: 'PA' },
        { code: 'PB', description: 'PB' },
        { code: 'PR', description: 'PR' },
        { code: 'PE', description: 'PE' },
        { code: 'PI', description: 'PI' },
        { code: 'RJ', description: 'RJ' },
        { code: 'RN', description: 'RN' },
        { code: 'RS', description: 'RS' },
        { code: 'RO', description: 'RO' },
        { code: 'RR', description: 'RR' },
        { code: 'SC', description: 'SC' },
        { code: 'SP', description: 'SP' },
        { code: 'SE', description: 'SE' },
        { code: 'TO', description: 'TO' }
      ],
    },
    'isMandatory': () => false,
  },
  'gender': {
    'path': 'gender',
    'label': 'Gênero',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': 'm', 'description': 'Masculino' },
        { 'code': 'f', 'description': 'Feminino' },
      ],
    },
    'isMandatory': () => false,
  },
  'education': {
    'path': 'education',
    'label': 'Grau de instrução',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': 'illiterate', 'description': 'Sem instrução formal', 'products': '', 'filter': '' },
        { 'code': 'primary', 'description': 'Primeiro grau completo', 'products': '', 'filter': '' },
        { 'code': 'secondary', 'description': 'Segundo grau completo', 'products': '', 'filter': '' },
        { 'code': 'college', 'description': 'Superior completo', 'products': '', 'filter': '' },
        { 'code': 'postgraduate', 'description': 'Pós graduação', 'products': '', 'filter': '' },
      ],
    },
    'isMandatory': () => false,
  },
  'loanReason': {
    'path': 'loanreason',
    'label': 'Motivo do empréstimo',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': 'party', 'description': 'Casamento ou outras festas', 'products': '', 'filter': '' },
        { 'code': 'asset-purchase', 'description': 'Compra de um bem de alto valor', 'products': '', 'filter': '' },
        { 'code': 'shopping', 'description': 'Fazer Compras', 'products': '', 'filter': '' },
        { 'code': 'study', 'description': 'Financiar estudos', 'products': '', 'filter': '' },
        { 'code': 'investment', 'description': 'Investimentos Financeiros', 'products': '', 'filter': '' },
        { 'code': 'own-business', 'description': 'Investir na minha empresa', 'products': '', 'filter': '' },
        { 'code': 'debt-settlement', 'description': 'Pagamento de outras dívidas', 'products': '', 'filter': '' },
        { 'code': 'home-renovation', 'description': 'Reforma de casa', 'products': '', 'filter': '' },
        { 'code': 'aesthetic', 'description': 'Tratamento estético', 'products': '', 'filter': '' },
        { 'code': 'medical', 'description': 'Tratamento médico', 'products': '', 'filter': '' },
        { 'code': 'trip', 'description': 'Viagem', 'products': '', 'filter': '' },
      ],
    },
    'isMandatory': () => false,
  },
  'closedReason': {
    'path': 'closedreason',
    'label': 'Motivo de fechamento',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': ':apply:<ep-std>', 'description': ':apply:<ep-std>' },
        { 'code': ':apply:<ep-premium>', 'description': ':apply:<ep-premium>' },
        { 'code': ':filter:<not-matching><ep-std>', 'description': ':filter:<not-matching><ep-std>' },
        { 'code': ':filter:<not-matching><ep-premium>', 'description': ':filter:<not-matching><ep-premium>' },
        { 'code': ':gaveup:<customer><ep-std>', 'description': ':gaveup:<customer><ep-std>' },
        { 'code': ':gaveup:<customer><ep-premium>', 'description': ':gaveup:<customer><ep-premium>' },
        { 'code': ':rejected:<no-supplier><ep-std>', 'description': ':rejected:<no-supplier><ep-std>' },
        { 'code': ':rejected:<no-supplier><ep-premium>', 'description': ':rejected:<no-supplier><ep-premium>' },
        { 'code': ':started:<ep-std>', 'description': ':started:<ep-std>' },
        { 'code': ':started:<ep-premium>', 'description': ':started:<ep-premium>' },
        { 'code': ':timeout:<timer><ep-std>', 'description': ':timeout:<timer><ep-std>' },
        { 'code': ':timeout:<timer><ep-premium>', 'description': ':timeout:<timer><ep-premium>' },
        { 'code': '#accepted#:started:<ep-std>', 'description': '#accepted#:started:<ep-std>' },
        { 'code': '#accepted#:started:<ep-premium>', 'description': '#accepted#:started:<ep-premium>' },
        { 'code': '#accepted#:gaveup:<customer><ep-std>', 'description': '#accepted#:gaveup:<customer><ep-std>' },
        {
          'code': '#accepted#:gaveup:<customer><ep-premium>',
          'description': '#accepted#:gaveup:<customer><ep-premium>',
        },
        {
          'code': '#accepted#:paydout:<portocred><portocred>',
          'description': '#accepted#:paydout:<portocred><portocred>',
        },
        {
          'code': '#accepted#:paydout:<API notification><noverde>',
          'description': '#accepted#:paydout:<API notification><noverde>',
        },
        { 'code': '#accepted#:paydout:<api><cbss>', 'description': '#accepted#:paydout:<api><cbss>' },
        { 'code': '#accepted#:paydout:<bulk><bv>', 'description': '#accepted#:paydout:<bulk><bv>' },
        { 'code': '#accepted#:paydout:<bulk><simplic>', 'description': '#accepted#:paydout:<bulk><simplic>' },
        { 'code': '#accepted#:paydout:<geru><geru>', 'description': '#accepted#:paydout:<geru><geru>' },
        { 'code': '#accepted#:paydout:<><rebel>', 'description': '#accepted#:paydout:<><rebel>' },
        { 'code': '#accepted#:timeout:<timer><ep-std>', 'description': '#accepted#:timeout:<timer><ep-std>' },
        { 'code': '#accepted#:timeout:<timer><ep-premium>', 'description': '#accepted#:timeout:<timer><ep-premium>' },
        {
          'code': '#accepted#:rejected:<no-supplier><ep-premium>',
          'description': '#accepted#:rejected:<no-supplier><ep-premium>',
        },
        {
          'code': '#accepted#:rejected:<no-supplier><ep-std>',
          'description': '#accepted#:rejected:<no-supplier><ep-std>',
        },
        { 'code': '#apply#:apply:<ep-std>', 'description': '#apply#:apply:<ep-std>' },
        { 'code': '#apply#:apply:<ep-premium>', 'description': '#apply#:apply:<ep-premium>' },
        {
          'code': '#apply#:filter:<general-criteria><ep-std>',
          'description': '#apply#:filter:<general-criteria><ep-std>',
        },
        {
          'code': '#apply#:filter:<general-criteria><ep-premium>',
          'description': '#apply#:filter:<general-criteria><ep-premium>',
        },
        { 'code': '#apply#:gaveup:<customer><ep-std>', 'description': '#apply#:gaveup:<customer><ep-std>' },
        { 'code': '#apply#:gaveup:<customer><ep-premium>', 'description': '#apply#:gaveup:<customer><ep-premium>' },
        { 'code': '#apply#:paydout:<><rebel>', 'description': '#apply#:paydout:<><rebel>' },
        {
          'code': '#apply#:paydout:<API notification><noverde>',
          'description': '#apply#:paydout:<API notification><noverde>',
        },
        { 'code': '#apply#:timeout:<timer><ep-std>', 'description': '#apply#:timeout:<timer><ep-std>' },
        { 'code': '#apply#:timeout:<timer><ep-premium>', 'description': '#apply#:timeout:<timer><ep-premium>' },
        { 'code': '#offers#:paydout:<portocred><portocred>', 'description': '#offers#:paydout:<portocred><portocred>' },
        { 'code': '#offers#:paydout:<bulk><bv>', 'description': '#offers#:paydout:<bulk><bv>' },
        {
          'code': '#offers#:paydout:<API notification><noverde>',
          'description': '#offers#:paydout:<API notification><noverde>',
        },
        { 'code': '#policy#:policy:<ep-std>', 'description': '#policy#:policy:<ep-std>' },
        { 'code': '#policy#:policy:<ep-premium>', 'description': '#policy#:policy:<ep-premium>' },
        { 'code': '#policy#:filter:<validation><ep-std>', 'description': '#policy#:filter:<validation><ep-std>' },
        {
          'code': '#policy#:filter:<validation><ep-premium>',
          'description': '#policy#:filter:<validation><ep-premium>',
        },
        { 'code': '#policy#:gaveup:<customer><ep-std>', 'description': '#policy#:gaveup:<customer><ep-std>' },
        { 'code': '#policy#:gaveup:<customer><ep-premium>', 'description': '#policy#:gaveup:<customer><ep-premium>' },
        { 'code': '#policy#:timeout:<timer><ep-std>', 'description': '#policy#:timeout:<timer><ep-std>' },
        { 'code': '#policy#:timeout:<timer><ep-premium>', 'description': '#policy#:timeout:<timer><ep-premium>' },
        { 'code': '#policy#:rejected:<no-supplier><ep-std>', 'description': '#policy#:rejected:<no-supplier><ep-std>' },
        {
          'code': '#policy#:rejected:<no-supplier><ep-premium>',
          'description': '#policy#:rejected:<no-supplier><ep-premium>',
        },
        { 'code': '#started#:started:<ep-std>', 'description': '#started#:started:<ep-std>' },
        { 'code': '#started#:started:<ep-premium>', 'description': '#started#:started:<ep-premium>' },
        { 'code': '#started#:gaveup:<customer><ep-std>', 'description': '#started#:gaveup:<customer><ep-std>' },
        { 'code': '#started#:gaveup:<customer><ep-premium>', 'description': '#started#:gaveup:<customer><ep-premium>' },
        { 'code': '#started#:timeout:<timer><ep-std>', 'description': '#started#:timeout:<timer><ep-std>' },
        { 'code': '#started#:timeout:<timer><ep-premium>', 'description': '#started#:timeout:<timer><ep-premium>' },
        {
          'code': '#started#:rejected:<no-supplier><ep-std>',
          'description': '#started#:rejected:<no-supplier><ep-std>',
        },
        {
          'code': '#started#:rejected:<no-supplier><ep-premium>',
          'description': '#started#:rejected:<no-supplier><ep-premium>',
        },
      ],
    },
    'isMandatory': () => false,
  },
  'utm_source': {
    'path': 'utm_source',
    'label': 'UTM Source',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'domainName': 'utm_source',
      'options': [
        {
          "code": "--sanitized--",
          "originalCode": "--sanitized--",
          "description": "--sanitized--",
          "type": "string"
        },
        {
          "code": "123qred",
          "originalCode": "123qred",
          "description": "123qred",
          "type": "string"
        },
        {
          "code": "ComparaOnline",
          "originalCode": "ComparaOnline",
          "description": "ComparaOnline",
          "type": "string"
        },
        {
          "code": "Dgmax",
          "originalCode": "Dgmax",
          "description": "Dgmax",
          "type": "string"
        },
        {
          "code": "Infleux",
          "originalCode": "Infleux",
          "description": "Infleux",
          "type": "string"
        },
        {
          "code": "MailChimp",
          "originalCode": "MailChimp",
          "description": "MailChimp",
          "type": "string"
        },
        {
          "code": "MaisMei",
          "originalCode": "MaisMei",
          "description": "MaisMei",
          "type": "string"
        },
        {
          "code": "Sendgrid",
          "originalCode": "Sendgrid",
          "description": "Sendgrid",
          "type": "string"
        },
        {
          "code": "active-emp",
          "originalCode": "active-emp",
          "description": "active-emp",
          "type": "string"
        },
        {
          "code": "activecampaign",
          "originalCode": "activecampaign",
          "description": "activecampaign",
          "type": "string"
        },
        {
          "code": "atendimento",
          "originalCode": "atendimento",
          "description": "atendimento",
          "type": "string"
        },
        {
          "code": "begrowth",
          "originalCode": "begrowth",
          "description": "begrowth",
          "type": "string"
        },
        {
          "code": "bing",
          "originalCode": "bing",
          "description": "bing",
          "type": "string"
        },
        {
          "code": "blog",
          "originalCode": "blog",
          "description": "blog",
          "type": "string"
        },
        {
          "code": "boavista",
          "originalCode": "boavista",
          "description": "boavista",
          "type": "string"
        },
        {
          "code": "brm1",
          "originalCode": "brm1",
          "description": "brm1",
          "type": "string"
        },
        {
          "code": "bxblue",
          "originalCode": "bxblue",
          "description": "bxblue",
          "type": "string"
        },
        {
          "code": "capitalempreend",
          "originalCode": "capitalempreend",
          "description": "capitalempreend",
          "type": "string"
        },
        {
          "code": "capitalempreendedo",
          "originalCode": "capitalempreendedo",
          "description": "capitalempreendedo",
          "type": "string"
        },
        {
          "code": "capitalempreendedor",
          "originalCode": "capitalempreendedor",
          "description": "capitalempreendedor",
          "type": "string"
        },
        {
          "code": "cenarioleads",
          "originalCode": "cenarioleads",
          "description": "cenarioleads",
          "type": "string"
        },
        {
          "code": "ciclic",
          "originalCode": "ciclic",
          "description": "ciclic",
          "type": "string"
        },
        {
          "code": "ciclick.com.br",
          "originalCode": "ciclick.com.br",
          "description": "ciclick.com.br",
          "type": "string"
        },
        {
          "code": "cleads",
          "originalCode": "cleads",
          "description": "cleads",
          "type": "string"
        },
        {
          "code": "clickcash",
          "originalCode": "clickcash",
          "description": "clickcash",
          "type": "string"
        },
        {
          "code": "compara",
          "originalCode": "compara",
          "description": "compara",
          "type": "string"
        },
        {
          "code": "comparaonline",
          "originalCode": "comparaonline",
          "description": "comparaonline",
          "type": "string"
        },
        {
          "code": "conexao",
          "originalCode": "conexao",
          "description": "conexao",
          "type": "string"
        },
        {
          "code": "contanews.com",
          "originalCode": "contanews.com",
          "description": "contanews.com",
          "type": "string"
        },
        {
          "code": "credihome",
          "originalCode": "credihome",
          "description": "credihome",
          "type": "string"
        },
        {
          "code": "creditas",
          "originalCode": "creditas",
          "description": "creditas",
          "type": "string"
        },
        {
          "code": "dgmax",
          "originalCode": "dgmax",
          "description": "dgmax",
          "type": "string"
        },
        {
          "code": "direct",
          "originalCode": "direct",
          "description": "direct",
          "type": "string"
        },
        {
          "code": "email",
          "originalCode": "email",
          "description": "email",
          "type": "string"
        },
        {
          "code": "expired_finanvc",
          "originalCode": "expired_finanvc",
          "description": "expired_finanvc",
          "type": "string"
        },
        {
          "code": "facebook",
          "originalCode": "facebook",
          "description": "facebook",
          "type": "string"
        },
        {
          "code": "fazaconta",
          "originalCode": "fazaconta",
          "description": "fazaconta",
          "type": "string"
        },
        {
          "code": "ferratum",
          "originalCode": "ferratum",
          "description": "ferratum",
          "type": "string"
        },
        {
          "code": "finan.vc",
          "originalCode": "finan.vc",
          "description": "finan.vc",
          "type": "string"
        },
        {
          "code": "financer",
          "originalCode": "financer",
          "description": "financer",
          "type": "string"
        },
        {
          "code": "fineasier.com",
          "originalCode": "fineasier.com",
          "description": "fineasier.com",
          "type": "string"
        },
        {
          "code": "fivedin",
          "originalCode": "fivedin",
          "description": "fivedin",
          "type": "string"
        },
        {
          "code": "folheto",
          "originalCode": "folheto",
          "description": "folheto",
          "type": "string"
        },
        {
          "code": "getg.com.br",
          "originalCode": "getg.com.br",
          "description": "getg.com.br",
          "type": "string"
        },
        {
          "code": "google",
          "originalCode": "google",
          "description": "google",
          "type": "string"
        },
        {
          "code": "gridmidia",
          "originalCode": "gridmidia",
          "description": "gridmidia",
          "type": "string"
        },
        {
          "code": "header",
          "originalCode": "header",
          "description": "header",
          "type": "string"
        },
        {
          "code": "home",
          "originalCode": "home",
          "description": "home",
          "type": "string"
        },
        {
          "code": "iagente",
          "originalCode": "iagente",
          "description": "iagente",
          "type": "string"
        },
        {
          "code": "idealprime",
          "originalCode": "idealprime",
          "description": "idealprime",
          "type": "string"
        },
        {
          "code": "idinheiro",
          "originalCode": "idinheiro",
          "description": "idinheiro",
          "type": "string"
        },
        {
          "code": "idinheiro2",
          "originalCode": "idinheiro2",
          "description": "idinheiro2",
          "type": "string"
        },
        {
          "code": "infocu.com.br",
          "originalCode": "infocu.com.br",
          "description": "infocu.com.br",
          "type": "string"
        },
        {
          "code": "konkero",
          "originalCode": "konkero",
          "description": "konkero",
          "type": "string"
        },
        {
          "code": "lendico",
          "originalCode": "lendico",
          "description": "lendico",
          "type": "string"
        },
        {
          "code": "mailchimp",
          "originalCode": "mailchimp",
          "description": "mailchimp",
          "type": "string"
        },
        {
          "code": "melhorplano",
          "originalCode": "melhorplano",
          "description": "melhorplano",
          "type": "string"
        },
        {
          "code": "member",
          "originalCode": "member",
          "description": "member",
          "type": "string"
        },
        {
          "code": "memivi.com.br",
          "originalCode": "memivi.com.br",
          "description": "memivi.com.br",
          "type": "string"
        },
        {
          "code": "mobills",
          "originalCode": "mobills",
          "description": "mobills",
          "type": "string"
        },
        {
          "code": "multifinanca.com",
          "originalCode": "multifinanca.com",
          "description": "multifinanca.com",
          "type": "string"
        },
        {
          "code": "noverde",
          "originalCode": "noverde",
          "description": "noverde",
          "type": "string"
        },
        {
          "code": "plusdin",
          "originalCode": "plusdin",
          "description": "plusdin",
          "type": "string"
        },
        {
          "code": "podiluminar",
          "originalCode": "podiluminar",
          "description": "podiluminar",
          "type": "string"
        },
        {
          "code": "popup",
          "originalCode": "popup",
          "description": "popup",
          "type": "string"
        },
        {
          "code": "pubzap-facebook",
          "originalCode": "pubzap-facebook",
          "description": "pubzap-facebook",
          "type": "string"
        },
        {
          "code": "pushalert",
          "originalCode": "pushalert",
          "description": "pushalert",
          "type": "string"
        },
        {
          "code": "qery",
          "originalCode": "qery",
          "description": "qery",
          "type": "string"
        },
        {
          "code": "qred",
          "originalCode": "qred",
          "description": "qred",
          "type": "string"
        },
        {
          "code": "querofinanciar",
          "originalCode": "querofinanciar",
          "description": "querofinanciar",
          "type": "string"
        },
        {
          "code": "responsys",
          "originalCode": "responsys",
          "description": "responsys",
          "type": "string"
        },
        {
          "code": "selectra",
          "originalCode": "selectra",
          "description": "selectra",
          "type": "string"
        },
        {
          "code": "sendgrid",
          "originalCode": "sendgrid",
          "description": "sendgrid",
          "type": "string"
        },
        {
          "code": "seucredito",
          "originalCode": "seucredito",
          "description": "seucredito",
          "type": "string"
        },
        {
          "code": "seucreditodigital",
          "originalCode": "seucreditodigital",
          "description": "seucreditodigital",
          "type": "string"
        },
        {
          "code": "sms",
          "originalCode": "sms",
          "description": "sms",
          "type": "string"
        },
        {
          "code": "soliciteagora.com",
          "originalCode": "soliciteagora.com",
          "description": "soliciteagora.com",
          "type": "string"
        },
        {
          "code": "unop.com.br",
          "originalCode": "unop.com.br",
          "description": "unop.com.br",
          "type": "string"
        },
        {
          "code": "vert",
          "originalCode": "vert",
          "description": "vert",
          "type": "string"
        },
        {
          "code": "virtuspay",
          "originalCode": "virtuspay",
          "description": "virtuspay",
          "type": "string"
        },
        {
          "code": "zenvia",
          "originalCode": "zenvia",
          "description": "zenvia",
          "type": "string"
        }
      ]
    }
  },
  'closedReasonRegex': {
    'path': 'closedreasonregex',
    'label': 'Motivo de fechamento (prefixo)',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': '#accepted#', 'description': '#accepted#' },
        { 'code': '#apply#', 'description': '#apply#' },
        { 'code': '#offers#', 'description': '#offers#' },
        { 'code': '#policy#', 'description': '#policy#' },
        { 'code': '#started#', 'description': '#started#' },
        { 'code': ':apply:', 'description': ':apply:' },
        { 'code': ':filter:', 'description': ':filter:' },
        { 'code': ':gaveup:', 'description': ':gaveup:' },
        { 'code': ':paydout:', 'description': ':paydout:' },
        { 'code': ':policy:', 'description': ':policy:' },
        { 'code': ':rejected:', 'description': ':rejected:' },
        { 'code': ':started:', 'description': ':started:' },
        { 'code': ':timeout:', 'description': ':timeout:' },
      ],
    },
    'isMandatory': () => false,
  },
  'bankName': {
    'path': 'bankname',
    'label': 'Nome do banco',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': '001', 'description': 'Banco do Brasil', 'products': '', 'fiter': 'bvcc' },
        { 'code': '237', 'description': 'Bradesco', 'products': '', 'fiter': 'bvcc' },
        { 'code': '104', 'description': 'Caixa Economica Federal', 'products': '', 'filter': '' },
        { 'code': '341', 'description': 'Itaú', 'products': '', 'filter': '' },
        { 'code': '033', 'description': 'Santander', 'products': '', 'fiter': 'bvcc' },
        { 'code': '121', 'description': 'Banco Agibank', 'products': '', 'filter': '' },
        { 'code': '003', 'description': 'Banco da Amazônia', 'products': '', 'filter': '' },
        { 'code': '047', 'description': 'Banco do Estado de Sergipe', 'products': '', 'filter': '' },
        { 'code': '037', 'description': 'Banco do Estado do Pará', 'products': '', 'filter': '' },
        { 'code': '004', 'description': 'Banco do Nordeste', 'products': '', 'filter': '' },
        { 'code': '077', 'description': 'Banco Inter', 'products': '', 'filter': '' },
        { 'code': '243', 'description': 'Banco Máxima', 'products': '', 'filter': '' },
        { 'code': '756', 'description': 'Bancoob / Sicoob', 'products': '', 'filter': '' },
        { 'code': '212', 'description': 'Banco Original', 'products': '', 'filter': '' },
        { 'code': '422', 'description': 'Banco Safra', 'products': '', 'filter': '' },
        { 'code': '655', 'description': 'Banco Votorantim', 'products': '', 'filter': '' },
        { 'code': '021', 'description': 'Banestes', 'products': '', 'filter': '' },
        { 'code': '041', 'description': 'Banrisul', 'products': '', 'filter': '' },
        { 'code': '318', 'description': 'BMG', 'products': '', 'filter': '' },
        { 'code': '070', 'description': 'BRB - Banco de Brasília', 'products': '', 'filter': '' },
        { 'code': '208', 'description': 'BTG Pactual', 'products': '', 'filter': '' },
        { 'code': '336', 'description': 'C6 Bank - C6 S.A.', 'products': '', 'filter': '' },
        { 'code': '085', 'description': 'Cooperativa Central de Credito - Ailos', 'products': '', 'filter': '' },
        { 'code': '745', 'description': 'Citibank', 'products': '', 'filter': '' },
        { 'code': '089', 'description': 'Credisan', 'products': '', 'filter': '' },
        { 'code': '389', 'description': 'Mercantil do Brasil', 'products': '', 'filter': '' },
        { 'code': '735', 'description': 'Neon', 'products': '', 'filter': '' },
        { 'code': '260', 'description': 'Nubank', 'products': '', 'filter': '' },
        { 'code': '254', 'description': 'Paraná Banco', 'products': '', 'filter': '' },
        { 'code': '748', 'description': 'Sicredi', 'products': '', 'filter': '' },
        { 'code': '637', 'description': 'Sofisa', 'products': '', 'filter': '' },
        { 'code': '218', 'description': 'BS2 - Bonsucesso', 'products': '', 'filter': '' },
        { 'code': '133', 'description': 'Cresol - Conf. Nac. das CCC Sol.', 'products': '', 'filter': '' },
      ],
    },
    'isMandatory': () => false,
  },
  'product': {
    'path': 'product',
    'label': 'Nome do produto',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [],
    },
    'isMandatory': () => false,
  },
  'workStatus': {
    'path': 'workstatus',
    'label': 'Ocupação',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': 'retired', 'description': 'Aposentado ou Pensionista' },
        { 'code': 'independent-contractor', 'description': 'Autônomo' },
        { 'code': 'unemployed', 'description': 'Desempregado' },
        { 'code': 'business-owner', 'description': 'Empresário ou PJ' },
        { 'code': 'public-employee', 'description': 'Funcionário Público' },
        { 'code': 'private-employee', 'description': 'Funcionário do Setor Privado' },
        { 'code': 'liberal-professional', 'description': 'Profissional Liberal' },
        { 'code': 'government-assistance', 'description': 'Programa Bolsa Família' },
      ],
    },
    'isMandatory': () => false,
  },
  'applicationState': {
    'path': 'applicationstate',
    'label': 'Status da proposta',
    'dataType': 'number',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { code: 1, description: 'Started' },
        { code: 2, description: 'Validated' },
        { code: 4, description: 'Completed' },
        { code: 8, description: 'Has Offers' },
        { code: 16, description: 'Closed' },
        { code: 32, description: 'Accepted' },
        { code: 64, description: 'Follow Up' },
        { code: 128, description: 'Paid' },
        { code: 0x7000, description: 'Excluded' },
      ],
    },
    'isMandatory': () => false,
  },
  'accountType': {
    'path': 'accounttype',
    'label': 'Tipo de conta bancária',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { "code": "special", "description": "Conta com cheque especial", "products": "", "filter": "" },
        { "code": "checking", "description": "Conta corrente comum", "products": "", "filter": "" },
        { "code": "salary", "description": "Conta salário", "products": "", "filter": "" },
        { "code": "savings", "description": "Poupança", "products": "", "filter": "" },
        { "code": "simplified", "description": "Conta Fácil Caixa / BB", "products": "", "filter": "" }
      ],
    },
    'isMandatory': () => false,
  },
  'uistate.hasCompletedApplication': {
    'path': 'uistate.hascompletedapplication',
    'label': 'Usuário completou proposta',
    'dataType': 'string',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': 'true', 'description': 'Sim' },
      ],
    },
    'isMandatory': () => false,
  },
  'hascreditrestrictions': {
    'path': 'hascreditrestrictions',
    'label': 'Usuário tem restrição de crédito?',
    'dataType': 'boolean',
    'inputType': 'select',
    'filterType': 'domain',
    'inputProps': {
      'options': [
        { 'code': 'true', 'description': 'Sim' },
        { 'code': 'false', 'description': 'Não' },
      ],
    },
    'isMandatory': () => false,
  },
  'created': {
    'path': 'created',
    'label': 'Data do Pedido',
    'dataType': 'date',
    'inputType': 'date',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'birthDate': {
    'path': 'birthDate',
    'label': 'Data de nascimento',
    'dataType': 'date',
    'inputType': 'date',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'householdMonthlyIncome': {
    'path': 'householdMonthlyIncome',
    'label': 'Renda total famíliar',
    'dataType': 'number',
    'inputType': 'number',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'monthlyIncome': {
    'path': 'monthlyIncome',
    'label': 'Renda mensal',
    'dataType': 'number',
    'inputType': 'number',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'hasBids': {
    'path': 'auction.bids',
    'label': 'Contenha pelo menos x bid(s)',
    'dataType': 'number',
    'inputType': 'select',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'auctionCreated': {
    'path': 'auction.created',
    'label': 'Data de Início do Leilão',
    'dataType': 'date',
    'inputType': 'date',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'auctionEndDate': {
    'path': 'auction.endDate',
    'label': 'Data de Término do Leilão',
    'dataType': 'date',
    'inputType': 'date',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'paidDate': {
    'path': 'paid.paidDate',
    'label': 'Data de Pago',
    'dataType': 'date',
    'inputType': 'date',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'lastInstallmentDate': {
    'path': 'paid.lastInstallmentDate',
    'label': 'Data de última parcela',
    'dataType': 'date',
    'inputType': 'date',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'firstInstallmentDate': {
    'path': 'paid.firstInstallmentDate',
    'label': 'Data da primeira parcela',
    'dataType': 'date',
    'inputType': 'date',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'createdDays': {
    'path': 'createdDays',
    'label': 'Dias de criação da proposta',
    'dataType': 'number',
    'inputType': 'number',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'monthsAgoLastInstallmentDate': {
    'path': 'monthsAgoLastInstallmentDate',
    'label': 'Mês anterior a ultima parcela',
    'dataType': 'number',
    'inputType': 'number',
    'filterType': 'range',
    'isMandatory': () => false,
  },
  'fzScore': {
    'path': 'scores.score',
    'label': 'Fz Score',
    'dataType': 'number',
    'inputType': 'number',
    'filterType': 'range',
    'isMandatory': () => false,
  },
};


