import get from 'lodash/get';
import moment from 'moment'
import forOwn from 'lodash/forOwn';
import api from '../../api/client-api'

/*
      path:                                --> application field
        label:                             --> Field label (on screen)
        section:                           --> Detalhes do Imóvel
        dataType:                          --> data type (on object)
        inputType:                         --> text, telephone, email, select, slider
        inputProps: {
          limits:                          -->  objecto with the minimum, maximum e step to slider
          domainName:                          -->  name the dominio for list the options for select
          filteredFrom:                      --> Based on domin property to filter the list of options
          options:                          --> list the options for select,
          forceEmpty
          maxValue:
          minValue:
          maxSize:
          minSize:
          autoComplete:                     --> is property of autoComplete for HTML input
          mask:                             --> mask. Only functional for numbers only
          removeMask:                       --> boolean that has the decision of onFocus  if the mask disappears
        }
        relatedFields                         ["city", "state", "neighbourhood", "address1"],
        validationMessage:                      "Error",   --> message shown to user when validation fails
        pattern:                             --> regex to validation
        defaultValue:                        --> default valor
        placeholder:                         --> Default input text
        tip:                                 --> text of tooltip. can use function
        product                              ---> list product were this field need show ["CP", "HE", "Refin"]
        isMandatory:                         --> true if mandatory. False or absent otherwise
        isReadOnly:                          -->  Read Only
        isVisible:                           --> hidden in form
        normalize:                           -->  Apply rules in the input: ex camelcase, strip and limit of characters
        extraValidation:                      --> function to extraValidation field
        hookOnSave : hookOnSave

*/

const camelCase = (value) => {
  let names = value ? value.split(" ") : []
  let normalized = []
  for (let i = 0; i < names.length; ++i) {
    if (names[i].length > 0) {
      let namePart = names[i]
      if (namePart.length > 2) {
        namePart = namePart[0].toUpperCase() + namePart.slice(1)
      }
      normalized.push(namePart)
    }
  }
  return normalized.join(" ")
}

const formatAccountNumber = (value) => {
  if (value && value !== '') {
    value = value.replace(/[-./a-zA-Z]/g, '');
    let match = /[\d]{1,13}/.exec(value);
    if (match) {
      let accountNumber = match[0];
      return accountNumber.replace(/(\d{1,12})(\d)$/, '$1-$2');
    }
  }
  return value;
};



export const states = [
  { code: 'AC', description: 'AC' },
  { code: 'AL', description: 'AL' },
  { code: 'AP', description: 'AP' },
  { code: 'AM', description: 'AM' },
  { code: 'BA', description: 'BA' },
  { code: 'CE', description: 'CE' },
  { code: 'DF', description: 'DF' },
  { code: 'ES', description: 'ES' },
  { code: 'GO', description: 'GO' },
  { code: 'MA', description: 'MA' },
  { code: 'MT', description: 'MT' },
  { code: 'MS', description: 'MS' },
  { code: 'MG', description: 'MG' },
  { code: 'PA', description: 'PA' },
  { code: 'PB', description: 'PB' },
  { code: 'PR', description: 'PR' },
  { code: 'PE', description: 'PE' },
  { code: 'PI', description: 'PI' },
  { code: 'RJ', description: 'RJ' },
  { code: 'RN', description: 'RN' },
  { code: 'RS', description: 'RS' },
  { code: 'RO', description: 'RO' },
  { code: 'RR', description: 'RR' },
  { code: 'SC', description: 'SC' },
  { code: 'SP', description: 'SP' },
  { code: 'SE', description: 'SE' },
  { code: 'TO', description: 'TO' }
]
/**
 * @typedef {import("../../types/metas").Meta} Meta
 */
/**
 * @typedef {object} MetaType
 * @property {object} appMeta
 * @property {Meta} appMeta.fields
 * 
 */
const metaData = {
  appMeta: {
    getField: (path) => {
      return get(metaData.appMeta.fields, path)
    },
    getFieldByPath: (applicationPath) => {
      return get(metaData.appMeta.fields, Object.keys(metaData.appMeta.fields).find(fieldPath => metaData.appMeta.fields[fieldPath].path === applicationPath.toLowerCase()))
    },
    getFieldInsensitive: (path) => {
      return get(metaData.appMeta.fields, Object.keys(metaData.appMeta.fields).find(fieldPath => fieldPath.toLowerCase() === path.toLowerCase()))
    },
    getFieldPaths: (application) => {
      return colectFields(metaData.appMeta.fields, "", application.product)
    },


    fields: {
      "__allColumns": ["id", "product", "applicationState", "closedReason", "created",
        "updated", "appliedAmount", "city", "personalNumber", "email", "customer",
        "fullName", "monthlyIncome", "vehicleSecurity.value", "vehicleSecurity.brand",
        "homeSecurity.value", "relativeTime", "trackingData.utm_campaign",
        "trackingData.utm_medium", "trackingData.utm_source", "bidsWeight",
        "paidAmount", "paidDate"],
      "__defaultVisibleColumns": ["id", "product", "applicationState", "created", "appliedAmount", "customer", "relativeTime", "bidsWeight", "paidAmount", "paidDate"],
      "id": {
        "path": "id",
        "label": "App Id",
        "section": "application",
        "dataType": "number",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "14ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": false,
          "dataAlign": "center",
          "columnFormat": "applicationIdColumn",
          "isKey": true,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "fzKey": {
        "path": "fzKey",
        "label": "#",
        "section": "application",
        "dataType": "number",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "7ch",
          "filter": undefined,
          "dataSort": true,
          "dataAlign": "center",
          "columnFormat": undefined,
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "refKey": {
        "path": "id",
        "label": "App Id",
        "section": "application",
        "dataType": "number",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "14ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": false,
          "dataAlign": "center",
          "columnFormat": undefined,
          "isKey": true,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "applicationState": {
        "path": "applicationState",
        "label": "Situação",
        "section": "application",
        "domainName": "application-state",
        "dataType": "number",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "application-state",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "17ch",
          "filter": { "type": "SelectDomain" },
          "dataSort": false,
          "dataAlign": "center",
          "columnFormat": "getDescriptionsByDomains",
          "formatExtraData": "application-state",
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "closedReason": {
        "path": "closedReason",
        "label": "Motivo do fechamento",
        "section": "application",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "17ch",
          "filter": undefined,
          "dataSort": false,
          "dataAlign": "center",
          "columnFormat": undefined,
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "created": {
        "path": "created",
        "label": "Data do Pedido",
        "section": "application",
        "dataType": "date",
        "inputType": "date",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "18ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": "center",
          "columnFormat": "dateColumn",
          "exportFormat": "formatPeriodForExport",
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "updated": {
        "path": "updated",
        "label": "Atualizado em",
        "section": "application",
        "dataType": "date",
        "inputType": "date",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "14ch",
          "filter": undefined,
          "dataSort": true,
          "dataAlign": "center",
          "columnFormat": "dateColumn",
          "exportFormat": "formatPeriodForExport",
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "customer": {
        "path": "customer",
        "label": "Cliente",
        "section": "application",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "30ch",
          "filter": undefined,
          "dataSort": false,
          "dataAlign": "right",
          "columnFormat": "customerColumn",
          "isKey": false,
          "headerText": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "trackingData": {
        "utm_campaign": {
          "path": "trackingData.utm_campaign",
          "label": "Campanha",
          "section": "application",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "20ch",
            "filter": { type: 'TextFilter', delay: 500 },
            "dataSort": true,
            "dataAlign": "left",
            "columnFormat": undefined,
            "isKey": false,
            "headerText": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "utm_source": {
          "path": "trackingData.utm_source",
          "label": "Origem",
          "section": "application",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "20ch",
            "filter": { type: 'TextFilter', delay: 500 },
            "dataSort": true,
            "dataAlign": "left",
            "columnFormat": undefined,
            "isKey": false,
            "headerText": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "utm_medium": {
          "path": "trackingData.utm_medium",
          "label": "Mídia",
          "section": "application",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "20ch",
            "filter": { type: 'TextFilter', delay: 500 },
            "dataSort": true,
            "dataAlign": "left",
            "columnFormat": undefined,
            "isKey": false,
            "headerText": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
      },
      "fullName": {
        "path": "fullName",
        "label": "Nome completo (igual ao RG)",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Nome",
          "width": "15ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": undefined,
          "dataAlign": "center",
          "columnFormat": undefined,
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o nome completo (igual ao RG)",
        "pattern": /(\D{2,60})\s(\D{2,60}\s?)+$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (val) => camelCase(val),
        "extraValidation": undefined,
        "hookOnSave": undefined,
      },
      "birthDate": {
        "path": "birthDate",
        "label": "Data de nascimento",
        "section": "personal-data",
        "dataType": "date",
        "inputType": "date",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": moment().add(-18, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
          "minValue": moment().add(-110, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe a data de nascimento",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => true,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "gender": {
        "path": "gender",
        "label": "Gênero",
        "section": "personal-data",
        "dataType": "text",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [{ "code": "m", "description": "Masculino" }, { "code": "f", "description": "Feminino" }],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe gênero",
        "pattern": /^[mf]{1}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "email": {
        "path": "email",
        "label": "Email",
        "section": "identifier",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "email",
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "30ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": true,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Email inválido",
        "pattern": /^.{10,128}$/,
        "placeholder": "joao.carlos@exemplo.com.br",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => true,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => { return value !== undefined ? value.toLowerCase() : value },
        "extraValidation": "email",
        "hookOnSave": undefined
      },
      "personalNumber": {
        "path": "personalNumber",
        "label": "CPF",
        "section": "identifier",
        "dataType": "text",
        "inputType": "masked",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": "111.111.111-11",
          "removeMask": true
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "20ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Numero de CPF inválido",
        "pattern": /^\d{11}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => true,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => { if (/^((\\d{11})|(\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2})|(\\d{9}-\\d{2}))$/.test(value)) { return value.replace(/\\D/g, ''); } else { return value } },
        "extraValidation": "personalNumber",
        "hookOnSave": undefined
      },
      "civilStatus": {
        "path": "civilStatus",
        "label": "Estado civil ",
        "section": "personal-data",
        "dataType": "text",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "civil-status",
          "filteredFrom": undefined,
          "options": [
            {
              "code": "single",
              "description": "Solteiro(a)"
            },
            {
              "code": "married",
              "description": "Casado(a)",
              "productNick": "",
              "filter": ""
            },
            {
              "code": "divorced",
              "description": "Divorciado(a)",
              "productNick": "",
              "filter": ""
            },
            {
              "code": "widowed",
              "description": "Viúvo(a)",
              "productNick": "",
              "filter": ""
            }
          ],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "honorific-prefix",
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o estado civil",
        "pattern": /^.{2,}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === "CP" || application.product === "Refin" || application.product ==="HE",
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "education": {
        "path": "education",
        "label": "Grau de instrução",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "education",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe grau de instrução",
        "pattern": /^.{2,60}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === "CP" || application.product === "Refin" || application.product ==="HE",
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "placeOfBirth": {
        "path": "placeOfBirth",
        "label": "Naturalidade",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe a cidade de nascimento",
        "pattern": /^.{3,60}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (val) => camelCase(val),
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "stateOfBirth": {
        "path": "stateOfBirth",
        "label": "Estado nascimento",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "states",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o estado de nascimento",
        "pattern": /^[A-Z]{2}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product ==='HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "nationality": {
        "path": "nationality",
        "label": "Nacionalidade",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "nationality",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o país de nascimento",
        "pattern": /^.{2,5}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === "CP" || application.product === "Refin" ,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "mothersName": {
        "path": "mothersName",
        "label": "Nome da mãe",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe nome completo da mãe (igual ao RG)",
        "pattern": /(\D{2,60})\s(\D{2,60}\s?)+$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (val) => camelCase(val),
        "extraValidation": "personName",
        "hookOnSave": undefined
      },
      "telephoneNumber": {
        "path": "telephoneNumber",
        "label": "Telefone",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "maskedPhone",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "tel",
          "mask": "(11) 1 1111 1111",
          "removeMask": true,
          "whatsappOptin": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe um número com DDD: (xx) xxxxx-xxxx",
        "pattern": /^\d{10,11}$/,
        "placeholder": "(xx) xxxxx xxxx",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => true,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => { return value !== undefined ? value.toLowerCase() : value },
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "RGDocType": {
        "path": "RGDocType",
        "label": "Tipo de documento",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "rg-document-type",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o tipo de documento",
        "pattern": /^.{2,}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "RG": {
        "path": "RG",
        "label": "Número do documento escolhido",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o número do documento escolhido",
        // "pattern": /^.{4,25}$/,
        "placeholder": "Número do documento escolhido",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": "RG",
        "hookOnSave": undefined
      },
      "RGExpeditionDate": {
        "path": "RGExpeditionDate",
        "label": "Data de emissão do documento escolhido",
        "section": "personal-data",
        "dataType": "date",
        "inputType": "date",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),  // TODO chek S esta correto
          "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe data de emissão do documento escolhido",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": "Data em que o documento escolhido foi emitido",
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => { if (application.product === "CP" || application.product === "Refin") { return true } else { return false } },
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "RGExpeditorOrganisation": {
        "path": "RGExpeditorOrganisation",
        "label": "Órgão emissor do documento",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "rg-expedition-organization",
          "filteredFrom": "RGDocType",
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o órgão emissor do documento de identidade",
        "pattern": /^.{2,10}$/,
        "placeholder": undefined,
        "tip": (v, application) => {
          if (application && application.RGDocType === "rg" && application.RGExpeditorOrganisation === "ssp") {
            return "SSP é \"Secretária de Segurança Pública\" e é o orgão mais comum de emissão de RG do Brasil"
          } else {
            return "É o órgão que fez a impressão do documento de identificação"
          }
        },
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "RGOriginState": {
        "path": "RGOriginState",
        "label": "Estado de emissão do documento escolhido",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "states",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Escolha o estado em que o documento foi emitido",
        "pattern": /^[A-Z]{2}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => { if (application.product === "CP" || application.product === "Refin") { return true } else { return false } },
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "mediaSurvey": {
        "path": "mediaSurvey",
        "label": "Como nos conheceu?",
        "section": "personal-data",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "media-survey",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Nos informe como nos conheceu",
        "pattern": undefined,
        "placeholder": "Ex: internet, amigos",
        "tip": undefined,
        "product": ["CP", "HE", "Refin", "Consorcio"],
        "isMandatory": () => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "product": {
        "path": "product",
        "label": "Produto",
        "section": "operation-details",
        "dataType": "string",
        "inputType": "select",
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Produto",
          "width": "8ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Escolha um produto",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin", "Consorcio"],
        "isMandatory": (application) => true,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "appliedAmount": {
        "path": "appliedAmount",
        "label": "Valor solicitado",
        "section": "operation-details",
        "dataType": "number",
        "inputType": "number",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "12ch",
          "filter": undefined,
          "dataSort": true,
          "dataAlign": "right",
          "columnFormat": "appliedAmountColumn",
          "exportFormat": "formatCurrency"
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o valor desejado",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": (value, application) => {
          let isRefinOrFin = application && /refin|fin/i.test(application.product);

          if (isRefinOrFin) {
            return "O valor do empréstimo dependerá do quanto vale o veículo quitado"
          }
        },
        "product": ["CP", "HE", "Refin", "Consorcio"],
        "isMandatory": (application) => application.product !== 'Cartao',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": "appliedAmount",
        "hookOnSave": undefined
      },
      "loanPeriodInMonths": {
        "path": "loanPeriodInMonths",
        "label": "Nº de prestações",
        "section": "operation-details",
        "dataType": "number",
        "inputType": "select",
        "inputProps": {
          "domainName": "loan-months",
          "filteredFrom": "product",
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": '7em',
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": "right",
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o período desejado para o empréstimo",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin", "Consorcio"],
        "isMandatory": (application) => true,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "maxInstallment": {
        "path": "maxInstallment",
        "label": "Parcela ideal",
        "section": "operation-details",
        "dataType": "number",
        "inputType": "number",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "12ch",
          "filter": undefined,
          "dataSort": true,
          "dataAlign": "right",
          "columnFormat": "maxInstallmentColumn",
          "exportFormat": "formatCurrency"
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o valor de parcela ideal",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["Consorcio"],
        "isMandatory": (application) => application.product === 'Consorcio',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": "maxInstallment",
        "hookOnSave": undefined
      },
      "loanReason": {
        "path": "loanReason",
        "label": "Motivo do empréstimo",
        "section": "operation-details",
        "dataType": "string",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "loan-reason",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": "10em",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": "getDescriptionsByDomains",
          "formatExtraData": "loan-reason",
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o motivo do empréstimo",
        "pattern": /^.{4,16}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "cardBillDay": {
        "path": "cardBillDay",
        "label": "Data de pagamento cartão",
        "section": "operation-details",
        "dataType": "number",
        "inputType": "select",
        "inputProps": {
          "domainName": 'card-bill-day',
          "options": [
            {
              "code": 1,
              "description": "dia 01"
            },
            {
              "code": 5,
              "description": "dia 05"
            },
            {
              "code": 7,
              "description": "dia 07"
            },
            {
              "code": 10,
              "description": "dia 10"
            },
            {
              "code": 15,
              "description": "dia 15"
            },
            {
              "code": 20,
              "description": "dia 20"
            },
            {
              "code": 30,
              "description": "dia 30"
            }
          ],
        },
        "validationMessage": "Informe data de pagamento do cartão",
        "isMandatory": (application) => application.product === 'Cartao',
      },
      "fgtsSecurity.value": {
        "path": "fgtsSecurity.value",
        "label": "Saldo FGTS",
        "section": 'operation-details',
        "dataType": "number",
        "inputType": "number",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o saldo FGTS",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["FGTS"],
        "isMandatory": (application) => false,
        "isReadOnly": () => true,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "securitySubtype": {
        "path": "securitySubtype",
        "label": "Tipo de garantia",
        "section": "operation-details",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "securitysubtype",
          "filteredFrom": "product",
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Selecione o tipo de veículo",
        "pattern": /^.{2,60}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["Refin"],
        "isMandatory": (application) => application.product === 'Refin' || application.product === 'Consorcio',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },

      "supplierData": {
        "reserved": {
          "path": "supplierData.name",
          "label": "Reservado",
          "section": "supplierData",
          "dataType": "bool",
          "inputType": "bool",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": "reserved",
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
            "exportFormat": undefined,
            "formatExtraData": "reserved"
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": undefined,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "sent": {
          "path": "supplierData.sent",
          "label": "Enviado p parceiro",
          "section": "supplierData",
          "dataType": "bool",
          "inputType": "bool",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": "sent",
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
            "exportFormat": undefined,
            "formatExtraData": "sent"
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": undefined,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      "vehicleSecurity": {
        
        "manufacturingYear": {
          "path": "vehicleSecurity.manufacturingYear",
          "label": "Ano de fabricação",
          "section": "vehicle-security-data",
          "dataType": "number",
          "inputType": "text",
          "inputProps": {
            "domainName": "manufacturing-year",
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o ano de fabricação do veículo",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["Refin"],
          "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
          "isReadOnly": () => true,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
        },
        "modelYear": {
          "path": "vehicleSecurity.modelYear",
          "label": "Ano modelo",
          "section": "vehicle-security-data",
          "dataType": "number",
          "inputType": "text",
          "inputProps": {
            "domainName": "model-year",
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o ano do modelo do veículo",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["Refin"],
          "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
          "isReadOnly": () => true,
          "isVisible": undefined,
          "normalize": undefined,
        },
        "brand": {
          "path": "vehicleSecurity.brand",
          "label": "Marca",
          "section": "vehicle-security-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": { type: 'TextFilter', delay: 500 },
            "dataSort": true,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe a marca do veículo",
          "placeholder": undefined,
          "tip": "Escreva as primeiras letras da marca para filtrar as opções da lista",
          "product": ["Refin"],
          "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
          "isReadOnly": () => true,
          "isVisible": undefined,
          "normalize": undefined,
        },
        // "model": {
        //   "path": "vehicleSecurity.model",
        //   "label": "Modelo",
        //   "section": "vehicle-security-data",
        //   "dataType": "text",
        //   "inputType": "auto-complete",
        //   "inputProps": {
        //     "domainName": "models",
        //     "filteredFrom": undefined,
        //     "options": undefined,
        //     "maxValue": undefined,
        //     "minValue": undefined,
        //     "maxSize": undefined,
        //     "minSize": undefined,
        //     "autoComplete": undefined,
        //     "mask": undefined,
        //     "removeMask": undefined,
        //   },
        //   "tableProps": {
        //     "viewsTable": undefined,
        //     "hidden": false,
        //     "shortLabel": undefined,
        //     "width": undefined,
        //     "filter": undefined,
        //     "dataSort": undefined,
        //     "dataAlign": undefined,
        //     "columnFormat": undefined,
        //   },
        //   "relatedFields": undefined,
        //   "validationMessage": "Informe o modelo do veículo",
        //   "placeholder": undefined,
        //   "tip": "Escreva as primeiras letras do modelo para filtrar as opções da lista",
        //   "product": ["Refin"],
        //   "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
        //   "isReadOnly": undefined,
        //   "isVisible": false,
        //   "normalize": undefined,
        // },
        "modelFull": {
          "path": "vehicleSecurity.modelFull",
          "label": "Modelo",
          "section": "vehicle-security-data",
          "dataType": "text",
          "inputType": "auto-complete",
          "inputProps": {
            "domainName": "models",
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined,
            "copyButton": {
              "text": "Copiar Modelo",
              "action": (value) => {
                let input = document.createElement('input');
                input.setAttribute('value', value);
                document.body.appendChild(input);
                input.select();
                let result = document.execCommand('copy');
                document.body.removeChild(input);
                return result;
              }
            }
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o modelo do veículo",
          "placeholder": undefined,
          "tip": "Escreva as primeiras letras do modelo para filtrar as opções da lista",
          "product": ["Refin"],
          "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "hookOnSave": "hookGetCarData"
        },
        "value": {
          "path": "vehicleSecurity.value",
          "label": "Valor de mercado",
          "section": 'vehicle-security-data',
          "dataType": "number",
          "inputType": "number",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": "Valor do Veículo",
            "width": "12ch",
            "filter": undefined,
            "dataSort": true,
            "dataAlign": "right",
            "columnFormat": "numberColumn"
          },
          "relatedFields": ["appliedAmount"],
          "validationMessage": "Informe o valor do veículo",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": (value, application) => {
            const notOnFirstPage = application && (application.applicationState || 1) > 1;
            if (notOnFirstPage) {
              return "O valor de mercado do veículo é calculado com base nos detalhes informados anteriormente";
            }
          },
          "product": ["Refin"],
          "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
          "isReadOnly": () => true,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": "value",
        },
        "downPayment": {
          "path": "vehicleSecurity.downPayment",
          "label": "Valor da entrada",
          "section": 'vehicle-security-data',
          "dataType": "number",
          "inputType": "number",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o valor da entrada",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["Fin"],
          "isMandatory": (application) => false,
          "isReadOnly": () => true,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "registrationNumber": {
          "path": "vehicleSecurity.registrationNumber",
          "label": "Placa",
          "section": 'vehicle-security-data',
          "dataType": "string",
          "inputType": "masked",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": "AAA-1#11",
            "removeMask": undefined,
            "className": 'form-control'
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": ['vehicleSecurity.registrationNumber', 'vehicleSecurity.modelFull', 'vehicleSecurity.brand'],
          "validationMessage": "Informe a placa do veículo",
          "placeholder": undefined,
          "tip": undefined,
          "product": ["Refin"],
          "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": (value) => {
            let parsedValue = value.split('_').join('')
            parsedValue = parsedValue.split('-').join('')
            return parsedValue ? parsedValue.toUpperCase() : ''
          },
          "hookOnSave": "hookSetModelsOrData"
        },
        "color": {
          "path": "vehicleSecurity.color",
          "label": "Cor do veículo",
          "section": 'vehicle-security-data',
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe a cor do veículo",
          "pattern": /^.{2,30}$/,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["Refin"],
          "isMandatory": (application) => false,
          "isReadOnly": () => true,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "hasExistingLoans": {
          "path": "vehicleSecurity.hasExistingLoans",
          "label": "O veículo possui dívida",
          "section": 'vehicle-security-data',
          "dataType": "bool",
          "inputType": "select",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": [
              { "code": "true", "description": "Sim" },
              { "code": "false", "description": "Não" }
            ],
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe se o veículo usado como garantia tem dívidas",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["Refin"],
          "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "valueOfExistingLoans": {
          "path": "vehicleSecurity.valueOfExistingLoans",
          "label": "Quanto falta para quitar o veículo",
          "section": 'vehicle-security-data',
          "dataType": "number",
          "inputType": "number",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o valor restante da dívida",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": "Valor aproximado do restante do financiamento",
          "product": ["Refin"],
          "isMandatory": (application) => { if (application.product === "Refin") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "accessoryPackage": {
          "path": "vehicleSecurity.accessoryPackage",
          "label": "Completo/Básico",
          "section": "vehicle-security-data",
          "dataType": "string",
          "inputType": "select",
          "product": ["Refin"],
          "inputProps": {
            "domainName": 'accessory-packages',
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "12ch",
            "filter": undefined,
            "dataSort": true,
            "dataAlign": "right",
            "columnFormat": "maxInstallmentColumn",
            "exportFormat": "formatCurrency"
          },
          "isMandatory": (application) => application.product === 'Refin',
          "isReadOnly": () => true,
        },
        "fuelType": {
          "path": "vehicleSecurity.fuelType",
          "label": "Tipo de Combustível",
          "section": "vehicle-security-data",
          "dataType": "string",
          "inputType": "select",
          "product": ["Refin"],
          "inputProps": {
            "domainName": 'fuel-types',
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "12ch",
            "filter": undefined,
            "dataSort": true,
            "dataAlign": "right",
            "columnFormat": "maxInstallmentColumn",
            "exportFormat": "formatCurrency"
          },
          "isMandatory": (application) => application.product === 'Refin',
          "isReadOnly": () => true,
        },
        "numberOfDoors": {
          "path": "vehicleSecurity.numberOfDoors",
          "label": "Número de Portas",
          "section": "vehicle-security-data",
          "dataType": "number",
          "inputType": "text",
          "product": ["Refin"],
          "inputProps": {
            "domainName": 'NumberOfDoors',
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "12ch",
            "filter": undefined,
            "dataSort": true,
            "dataAlign": "right",
            "columnFormat": "maxInstallmentColumn",
            "exportFormat": "formatCurrency"
          },
          "isMandatory": (application) => application.product === 'Refin',
          "isReadOnly": () => true
        },
      },
      "homeSecurity": {
        "value": {
          "path": "homeSecurity.value",
          "label": "Valor do imóvel",
          "section": "home-security-data",
          "dataType": "number",
          "inputType": "number",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "12ch",
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": "right",
            "columnFormat": "appliedAmountColumn",
            "formatExtraData": "homeSecurity.value"

          },
          "relatedFields": undefined,
          "validationMessage": "Informe o valor do imóvel",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "valueOfExistingLoans": {
          "path": "homeSecurity.valueOfExistingLoans",
          "label": "Valor das dívidas",
          "section": "home-security-data",
          "dataType": "number",
          "inputType": "number",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o valor de financiamento ou dívidas atuais",
          "pattern": undefined,
          "placeholder": "Caso não haja dívidas, colocar 0",
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "propertyType": {
          "path": "homeSecurity.propertyType",
          "label": "Tipo do imóvel",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "select",
          "inputProps": {
            "domainName": "property-type",
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o tipo do imóvel",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        'constructionType': {
          "path": "homeSecurity.constructionType",
          "label": "Tipo da construção do imóvel",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "select",
          "inputProps": {
            "domainName": "construction-type",
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o tipo da construção do imóvel",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "hasRegistrationNumber": {
          "path": "homeSecurity.hasRegistrationNumber",
          "label": "Tem o número de registro do imóvel?",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "select",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": [
              {
                "code": "yes",
                "description": "Sim"
              },
              {
                "code": "no",
                "description": "Não"
              },
              {
                "code": "do_not_know",
                "description": "Não sei"
              }
            ],
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe se tem o número de registro do imóvel",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => application.product === 'HE',
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "zipCode": {
          "path": "homeSecurity.zipCode",
          "label": "CEP",
          "section": "home-security-data",
          "dataType": "text",
          "inputType": "masked",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "postal-code",
            "mask": "#####-###",
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": ["homeSecurity.zipCode", "homeSecurity.neighbourhood", "homeSecurity.city", "homeSecurity.address1", "homeSecurity.state"],
          "validationMessage": "Informe o CEP",
          "pattern": /^\d{5}-?\d{3}$/,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => application.product === "HE",
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": (value) => value.replace("-", ""),
          "extraValidation": undefined,
          "hookOnSave": "hookSaveCep"
        },
        "address1": {
          "path": "homeSecurity.address1",
          "label": "Endereço",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-line1",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o endereço",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "streetNumber": {
          "path": "homeSecurity.streetNumber",
          "label": "Número",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-line2",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o número de residência",
          "pattern": /^\d{1,15}$/,
          "placeholder": "Número",
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "address2": {
          "path": "homeSecurity.address2",
          "label": "Complemento",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-line3",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": "Complemento",
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "neighbourhood": {
          "path": "homeSecurity.neighbourhood",
          "label": "Bairro",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-line3",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o bairro de residência",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "city": {
          "path": "homeSecurity.city",
          "label": "Cidade",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-level2",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe a cidade de residência",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "state": {
          "path": "homeSecurity.state",
          "label": "Estado",
          "section": "home-security-data",
          "dataType": "string",
          "inputType": "select",
          "inputProps": {
            "domainName": "states",
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-level1",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o estado de residência",
          "pattern": /^[A-Z]{2}$/,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["HE"],
          "isMandatory": (application) => { if (application.product === "HE") { return true } else { return false } },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      "zipCode": {
        "path": "zipCode",
        "label": "CEP",
        "section": "residence-data",
        "dataType": "text",
        "inputType": "masked",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "postal-code",
          "mask": "#####-###",
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": ["zipCode", "neighbourhood", "city", "address1", "state"],
        "validationMessage": "Informe o CEP",
        "pattern": /^\d{5}-?\d{3}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => value.replace("-", ""),
        "extraValidation": undefined,
        "hookOnSave": "hookSaveCep"
      },
      "city": {
        "path": "city",
        "label": "Cidade",
        "section": "residence-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "address-level2",
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "25ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": true,
          "dataAlign": undefined,
          "columnFormat": "cityColumn",
        },
        "relatedFields": undefined,
        "validationMessage": "Informe a cidade de residência",
        "pattern": /^.{3,60}$/,
        "placeholder": "Cidade",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "state": {
        "path": "state",
        "label": "Estado",
        "section": "residence-data",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "states",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "address-level1",
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o estado de residência",
        "pattern": /^[A-Z]{2}$/,
        "placeholder": "Estado?",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "address1": {
        "path": "address1",
        "label": "Endereço",
        "section": "residence-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "address-line1",
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o endereço",
        "pattern": /^.{5,60}$/,
        "placeholder": "Endereço, ex: Rua da Graça",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "streetNumber": {
        "path": "streetNumber",
        "label": "Número",
        "section": "residence-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "address-line2",
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o número de residência",
        "pattern": /^\d{1,15}$/,
        "placeholder": "Número",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "address2": {
        "path": "address2",
        "label": "Complemento",
        "section": "residence-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "address-line3",
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": "Complemento",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "neighbourhood": {
        "path": "neighbourhood",
        "label": "Bairro",
        "section": "residence-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "address-line3",
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o bairro de residência",
        "pattern": /^.{5,60}$/,
        "placeholder": "Bairro",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "housing": {
        "path": "housing",
        "label": "Reside em casa",
        "section": "residence-data",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "housing",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o tipo de residência",
        "pattern": /^.{2,30}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => ["CP", "Refin"].indexOf(application.product) > -1,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "workStatus": {
        "path": "workStatus",
        "label": "Ocupação",
        "section": "work-information",
        "dataType": "text",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "work-status",
          "filteredFrom": undefined,
          "options": [
            {
              "code": "public-employee",
              "description": "Funcionário Público"
            },
            {
              "code": "private-employee",
              "description": "Funcionário do Setor Privado"
            },
            {
              "code": "independent-contractor",
              "description": "Autônomo"
            },
            {
              "code": "business-owner",
              "description": "Empresário ou PJ"
            },
            {
              "code": "liberal-professional",
              "description": "Profissional Liberal"
            },
            {
              "code": "retired",
              "description": "Aposentado ou Pensionista"
            },
            {
              "code": "unemployed",
              "description": "Desempregado"
            },
            {
              "code": "government-assistance",
              "description": "Programa Bolsa Família"
            }
          ],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Selecione a ocupação principal",
        "pattern": /^.{2,30}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "householdMonthlyIncome": {
        "path": "householdMonthlyIncome",
        "label": "Renda total famíliar",
        "section": "work-information",
        "dataType": "currency",
        "inputType": "number",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": "right",
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "A renda mensal total da família deve ser de no mínimo R$ 3.000,00",
        "pattern": undefined,
        "placeholder": "A soma da renda de todos os integrantes da casa",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": "householdMonthlyIncome",
        "hookOnSave": undefined
      },
      "monthlyIncome": {
        "path": "monthlyIncome",
        "label": "Renda mensal",
        "section": "work-information",
        "dataType": "currency",
        "inputType": "number",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "12ch",
          "filter": undefined,
          "dataSort": true,
          "dataAlign": "right",
          "columnFormat": "numberColumn",
          "exportFormat": "formatCurrency"
        },
        "relatedFields": undefined,
        "validationMessage": "A renda mínima aceita é um salário mínimo e maxima de R$60 mil",
        "pattern": undefined,
        "placeholder": "Digite sua renda mensal",
        "tip": "Informar o  salário sem os descontos (valor bruto) ou o valor médio ganhado por mês",
        "product": ["CP", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": "monthlyIncome",
        "hookOnSave": undefined
      },
      "netWorth": {
        "path": "netWorth",
        "label": "Valor aproximado do patrimônio",
        "section": "work-information",
        "dataType": "number",
        "inputType": "select",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [
            {
              "code": "1",
              "description": "Não possuo patrimônio"
            },
            {
              "code": "10000",
              "description": "Até R$ 10 mil"
            },
            {
              "code": "50000",
              "description": "Entre R$ 10 mil e R$ 50 mil"
            },
            {
              "code": "300000",
              "description": "Entre R$ 50 mil e R$ 300 mil"
            },
            {
              "code": "500000",
              "description": "Acima de R$ 300 mil"
            }
          ],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o valor aproximado de bens",
        "pattern": undefined,
        "placeholder": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "companyName": {
        "path": "companyName",
        "label": "Empregador",
        "section": "work-information",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o nome do empregador ou fonte de renda",
        "pattern": /^.{2,60}$/,
        "placeholder": undefined,
        "tip": "A principal fonte de renda pode ser por exemplo: aluguel de casas, confeiteira, corretor de imóveis, etc",
        "product": ["CP", "HE"],
        "isMandatory": (application) => application.product === "CP" || application.product === "HE"  && application.workStatus !== "retired",
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "jobPosition": {
        "path": "jobPosition",
        "label": "Cargo",
        "section": "work-information",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "job-position",
          "filteredFrom": "workStatus",
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe a principal atividade ou cargo",
        "pattern": /^.{2,30}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === "CP" || application.product === "Refin" || application.product === "HE" && application.workStatus !== "retired",
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "employmentDate": {
        "path": "employmentDate",
        "label": "Início na empresa",
        "section": "work-information",
        "dataType": "date",
        "inputType": "date",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(), //TODO Check
          "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe há quanto tempo trabalha nessa atividade",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => { if (application.product === "CP" || application.product === "Refin") { return true } else { return false } },
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "incomeProofDocument": {
        "path": "incomeProofDocument",
        "label": "Comprovante de renda",
        "section": "work-information",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "income-proof-document",
          "filteredFrom": "workStatus",
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o comprovante de renda",
        "pattern": /^.{3,60}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "incomePaymentDay": {
        "path": "incomePaymentDay",
        "label": "Dia que recebe o pagamento",
        "section": "work-information",
        "dataType": "number",
        "inputType": "select",
        "inputProps": {
          "domainName": "income-payment-day",
          "options": [
            {
              "code": "1",
              "description": "dia 01"
            },
            {
              "code": "5",
              "description": "dia 05"
            },
            {
              "code": "7",
              "description": "dia 07"
            },
            {
              "code": "10",
              "description": "dia 10"
            },
            {
              "code": "15",
              "description": "dia 15"
            },
            {
              "code": "20",
              "description": "dia 20"
            },
            {
              "code": "30",
              "description": "dia 30"
            }
          ],
          "filteredFrom": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o dia que recebe o salário",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "incomePaymentMethod": {
        "path": "incomePaymentMethod",
        "label": "Método de pagamento da renda",
        "section": "work-information",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "income-payment-method",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe como recebe o benefício",
        "pattern": /^.{4,14}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },

      "benefitNumber": {
        "path": "benefitNumber",
        "label": "Número do Benefício",
        "section": "work-information",
        "dataType": "text",
        "inputType": "masked",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": "111.111.111-1",
          "removeMask": true
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "20ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o número benefício",
        "pattern": /^\d{10}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => { if (/^((\\d{11})|(\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2})|(\\d{9}-\\d{1}))$/.test(value)) { return value.replace(/\\D/g, ''); } else { return value } },
        "hookOnSave": undefined
      },
      "benefitType": {
        "path": "benefitType",
        "label": "Tipo de benefício",
        "section": "work-information",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "filteredFrom": undefined,
          "domainName": "benefit-type",
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o tipo de benefício",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "stateProvinceBenefit": {
        "path": "stateProvinceBenefit",
        "label": "Estado do benefício",
        "section": "work-information",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "states",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Escolha o estado do benefício",
        "pattern": /^[A-Z]{2}$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },

      "companyRegistrationNumber": {
        "path": "companyRegistrationNumber",
        "label": "CNPJ do empregador",
        "section": "work-information",
        "dataType": "string",
        "inputType": "masked",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": "11.111.111/1111-11",
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o CNPJ da empresa",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => { if (application.workStatus === "business-owner") { return true } else { return false } },
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => { if (/^((\d{14})|(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})|(\d{12}-\d{2}))$/.test(value)) { return value.replace(/\D/g, ''); } else { return value } },
        "extraValidation": "companyRegistrationNumber",
        "hookOnSave": undefined
      },
      "companyPhone": {
        "path": "companyPhone",
        "label": "Telefone da empresa",
        "section": "work-information",
        "dataType": "string",
        "inputType": "masked",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "tel",
          "mask": "(11) 1111 1111",
          "removeMask": true
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o telefone da empresa DDD: (xx) xxxx-xxxx",
        "pattern": undefined,
        "placeholder": "(xx) xxxx xxxx",
        "tip": "Caso trabalhe como autônomo, pode informar o número de um telefone fixo",
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => { return value !== undefined ? value.toLowerCase() : value },
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "companyZipCode": {
        "path": "companyZipCode",
        "label": "CEP da empresa",
        "section": "work-information",
        "dataType": "text",
        "inputType": "masked",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "postal-code",
          "mask": "#####-###",
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": ["companyZipCode", "companyNeighbourhood", "companyCity", "companyAddress", "companyState"],
        "validationMessage": "Informe o CEP da empresa",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => value.replace("-", ""),
        "extraValidation": undefined,
        "hookOnSave": "hookSaveCep",
      },
      "companyAddress": {
        "path": "companyAddress",
        "label": "Endereço da empresa",
        "section": "work-information",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o endereço da empresa",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (val) => camelCase(val),
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "companyStreetNumber": {
        "path": "companyStreetNumber",
        "label": "Número",
        "section": "work-information",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o número de residência",
        "pattern": undefined,
        "placeholder": "Número",
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "companyAddressComplement": {
        "path": "companyAddressComplement",
        "label": "Complemento",
        "section": "work-information",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "companyNeighbourhood": {
        "path": "companyNeighbourhood",
        "label": "Bairro",
        "section": "work-information",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "companyCity": {
        "path": "companyCity",
        "label": "Cidade",
        "section": "work-information",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "companyState": {
        "path": "companyState",
        "label": "Estado",
        "section": "work-information",
        "dataType": "string",
        "inputType": "select",
        "inputProps": {
          "domainName": "states",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "reference1Name": {
        "path": "reference1Name",
        "label": "Nome completo (dados da referência)",
        "section": "references",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe nome completo de alguém como referência",
        "pattern": /^.{3,60}$/,
        "placeholder": undefined,
        "tip": "Nome completo de alguém conhecido",
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (val) => camelCase(val),
        "extraValidation": "reference1Name",
        "hookOnSave": undefined
      },
      "reference1PhoneNumber": {
        "path": "reference1PhoneNumber",
        "label": "Telefone (dados da referência)",
        "section": "references",
        "dataType": "string",
        "inputType": "masked",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": "tel",
          "mask": "(11) 11111 1111",
          "removeMask": true
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe um número com DDD: (xx) 9xxxx-xxxx",
        "pattern": undefined,
        "placeholder": "(xx) 9xxxx xxxx",
        "tip": "Qual o telefone desta pessoa?",
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,  // TODO , PERGUNTAR PARA ANA
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => { return value !== undefined ? value.toLowerCase() : value },
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "accountType": {
        "path": "accountType",
        "label": "Tipo de conta bancária",
        "section": "bank-data",
        "dataType": "string",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "account-type",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined,
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Escolha um tipo de conta",
        "pattern": /^\w+$/,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product ==='HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (value) => value === "undefined" ? null : value,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "bankName": {
        "path": "bankName",
        "label": "Qual o banco?",
        "section": "bank-data",
        "dataType": "select",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "bank-name",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": "Dados Bancários",
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": "right",
          "columnFormat": "getDescriptionsByDomains",
          "formatExtraData": "bank-name",
        },
        "relatedFields": ["accountType", "bankName"],
        "validationMessage": "Informe o nome do banco",
        "pattern": /^[0-9]{3}$/,
        "placeholder": undefined,
        "tip": "A conta precisa estar no nome do solicitante, não são aceitas contas de outras pessoas.",
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": "bankName",
        "hookOnSave": undefined
      },
      "accountOpenDate": {
        "path": "accountOpenDate",
        "label": "Data de abertura da conta",
        "section": "bank-data",
        "dataType": "date",
        "inputType": "date",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
          "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe a data de abertura de conta",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "agency": {
        "path": "agency",
        "label": "Número da agência sem dígito",
        "section": "bank-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe o número da agência",
        "pattern": /^[0-9]{4}$/,
        "placeholder": "0000",
        "tip": "A agência deve ter pelo menos quatro dígitos (XXXX)",
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (val) => val && val !== '' ? /[0-9]{1,4}/.exec(val)[0] : val,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "accountNumber": {
        "path": "accountNumber",
        "label": "Número da conta com dígito",
        "section": "bank-data",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": ["accountType", "bankName", "agency", "accountNumber"],
        "validationMessage": "Informe o número da conta com dígito",
        "pattern": /^[0-9]+-\w{1}$/,
        "placeholder": "000000-0",
        "tip": "A conta deve ter o dígito separado por traço (XXXX-X)",
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": (val) => formatAccountNumber(val),
        "extraValidation": "accountNumber",
        "hookOnSave": undefined
      },
      "hasCreditRestrictions": {
        "path": "hasCreditRestrictions",
        "label": "Restrições de crédito",
        "section": "bank-data",
        "dataType": "bool",
        "inputType": "select",
        "filterType": "bool",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [
            { "code": true, "description": "Sim" },
            { "code": false, "description": "Não" },
          ],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Informe se há restrições de credito",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => application.product === 'CP' || application.product ==='Refin' || application.product === 'HE',
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "closed": {
        "path": "closed",
        "label": "Closed",
        "section": "no-section",
        "dataType": "bool",
        "inputType": "bool",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "hasBadCreditHistory": {
        "path": "hasBadCreditHistory",
        "label": "Tem Histórico ruim de crédito",
        "section": "no-section",
        "dataType": "bool",
        "inputType": "bool",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "hasCoApplicant": {
        "path": "hasCoApplicant",
        "label": "Tem coApplicant",
        "section": "no-section",
        "dataType": "bool",
        "inputType": "bool",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "startedBySelf": {
        "path": "startedBySelf",
        "label": "StartedBySelf",
        "section": "no-section",
        "dataType": "bool",
        "inputType": "bool",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "ownsVehicle": {
        "path": "ownsVehicle",
        "label": "Possui veículo",
        "section": "no-section",
        "dataType": "bool",
        "inputType": "bool",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "applicationstatefilter": {
        "path": "applicationstate",
        "label": "Status da proposta",
        "section": "no-section",
        "dataType": "number",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [
            { code: 1, description: "Started" },
            { code: 2, description: "Validated" },
            { code: 4, description: "Completed" },
            { code: 8, description: "Has Offers" },
            { code: 16, description: "Closed" },
            { code: 32, description: "Accepted" },
            { code: 64, description: "Follow Up" },
            { code: 128, description: "Paid" },
            { code: 0x7000, description: "Excluded" },
          ],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "auction": {
        "startDate": {
          "path": "auction.startDate",
          "label": "Start Auction",
          "section": "application",
          "dataType": "date",
          "inputType": "date",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "15ch",
            "filter": undefined,
            "dataSort": false,
            "dataAlign": "right",
            "columnFormat": undefined,
            "exportFormat": undefined,
            "isKey": false,
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "endDate": {
          "path": "auction.endDate",
          "label": "Finaliza Propostas",
          "section": "application",
          "dataType": "date",
          "inputType": "date",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "15ch",
            "filter": undefined,
            "dataSort": false,
            "dataAlign": "right",
            "columnFormat": "relativeTimeColumn",
            "exportFormat": "relativeTimeColumnDoExport",
            "isKey": false,
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "bidsWeight": {
          "path": "auction.bidsWeight",
          "label": "Força Bids",
          "section": "application",
          "dataType": "date",
          "inputType": "date",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "20ch",
            "filter": undefined,
            "dataSort": true,
            "dataAlign": "right",
            "columnFormat": "numberColumn",
            "isKey": false,
            "sortFunction": undefined,
            "headerText": "Peso relativo dos Bids",
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "bids": {
          "supplier": {
            "name": {
              "path": "name",
              "label": "Supplier",
              "section": "bid",
              "dataType": "text",
              "inputType": undefined,
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": "supplier",
                "width": undefined,
                "filter": undefined,
                "dataSort": undefined,
                "dataAlign": undefined,
                "columnFormat": undefined,
                "exportFormat": undefined,
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": ["CP", "HE", "Refin"],
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
            "internalName": {
              "path": "auction.bids.supplier.internalName",
              "label": "Supplier",
              "section": "bid",
              "dataType": "text",
              "inputType": undefined,
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": "supplier",
                "width": undefined,
                "filter": undefined,
                "dataSort": undefined,
                "dataAlign": undefined,
                "columnFormat": undefined,
                "exportFormat": undefined,
                "formatExtraData": "internalName"
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": ["CP", "HE", "Refin"],
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
          },
          "approvedAmount": {
            "path": "auction.bids",
            "label": "Valor total aprovado",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "approvedAmount",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "approvedAmount"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["CP", "HE", "Refin"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "approvedPeriodMonths": {
            "path": "auction.bids",
            "label": "Quantidade de Parcelas",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "approvedPeriodMonths",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": undefined,
              "formatExtraData": "approvedPeriodMonths"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["CP", "HE", "Refin"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "monthlyInstallment": {
            "path": "auction.bids",
            "label": "Prestação mensal",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "monthlyInstallment",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "monthlyInstallment"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["CP", "HE", "Refin"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "firstInstallment": {
            "path": "auction.bids",
            "label": "1ª Parcela",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "firstInstallment",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "firstInstallment"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["HE"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "lastInstallment": {
            "path": "auction.bids",
            "label": "Últ. Parcela",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "lastInstallment",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "lastInstallment"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["HE"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "totalValueInstallments": {
            "path": "auction.bids",
            "label": "Total Parcelas",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "totalValueInstallments",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "totalValueInstallments"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["HE"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "amortizationTable": {
            "path": "auction.bids",
            "label": "Tabela",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "Tabela",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "amortizationTable"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["HE"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "simulation": {
            "path": "auction.bids",
            "label": "Simulation",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "Tabela",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "simulation"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["HE"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "indexRate": {
            "path": "auction.bids",
            "label": "indexRate",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "indexRate",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "indexRate"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["HE"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "interestRate": {
            "path": "auction.bids",
            "label": "Taxa",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "interestRate",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": "formatCurrency",
              "formatExtraData": "interestRate"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["CP", "HE", "Refin"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "accepted": {
            "path": "auction.bids",
            "label": "Aceito",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "accepted",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": undefined,
              "formatExtraData": "accepted"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["CP", "HE", "Refin"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "refused": {
            "path": "auction.bids",
            "label": "Recusado",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": 'refused',
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": undefined,
              "formatExtraData": "refused"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["CP", "HE", "Refin"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "rejected": {
            "path": "auction.bids",
            "label": "Recusado",
            "section": "bid",
            "dataType": "number",
            "inputType": undefined,
            "inputProps": {
              "domainName": undefined,
              "filteredFrom": undefined,
              "options": undefined,
              "maxValue": undefined,
              "minValue": undefined,
              "maxSize": undefined,
              "minSize": undefined,
              "autoComplete": undefined,
              "mask": undefined,
              "removeMask": undefined
            },
            "tableProps": {
              "viewsTable": undefined,
              "hidden": false,
              "shortLabel": "rejected",
              "width": undefined,
              "filter": undefined,
              "dataSort": undefined,
              "dataAlign": undefined,
              "columnFormat": undefined,
              "exportFormat": undefined,
              "formatExtraData": "rejected"
            },
            "relatedFields": undefined,
            "validationMessage": undefined,
            "pattern": undefined,
            "placeholder": undefined,
            "tip": undefined,
            "product": ["CP", "HE", "Refin"],
            "isMandatory": undefined,
            "isReadOnly": undefined,
            "isVisible": undefined,
            "normalize": undefined,
            "extraValidation": undefined,
            "hookOnSave": undefined
          },
          "alternateOffers": {
            "offerId": {
              "path": "offerId",
              "label": "id da oferta",
              "section": "alternateOffers",
              "dataType": "number",
              "inputType": "text",
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": "Ações",
                "width": undefined,
                "filter": undefined,
                "dataSort": undefined,
                "dataAlign": "right",
                "columnFormat": undefined,
                "exportFormat": undefined,
                "formatExtraData": "offerId",
                "isKey": true,
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": undefined,
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
            "approvedAmount": {
              "path": "approvedAmount",
              "label": "Valor total aprovado",
              "section": "bid",
              "dataType": "number",
              "inputType": undefined,
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": "Aprovado",
                "width": undefined,
                "filter": { type: 'SelectFilter', condition: 'eq', options: "buildColumnValuesFilter" },
                "dataSort": undefined,
                "dataAlign": "right",
                "columnFormat": "formatCurrency",
                "exportFormat": "formatCurrency",
                "formatExtraData": undefined
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": ["CP", "HE", "Refin"],
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
            "monthlyInstallment": {
              "path": "monthlyInstallment",
              "label": "Prestação",
              "section": "bid",
              "dataType": "number",
              "inputType": undefined,
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": undefined,
                "width": undefined,
                "filter": undefined,
                "dataSort": undefined,
                "dataAlign": "right",
                "columnFormat": "formatCurrency",
                "exportFormat": "formatCurrency",
                "formatExtraData": undefined,
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": ["CP", "HE", "Refin"],
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
            "accepted": {
              "path": "auction.bids",
              "label": "Aceito",
              "section": "bid",
              "dataType": "number",
              "inputType": undefined,
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": "accepted",
                "width": undefined,
                "filter": undefined,
                "dataSort": undefined,
                "dataAlign": "right",
                "columnFormat": undefined,
                "exportFormat": undefined,
                "formatExtraData": undefined
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": ["CP", "HE", "Refin"],
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
            "approvedPeriodMonths": {
              "path": "approvedPeriodMonths",
              "label": "Meses",
              "section": "bid",
              "dataType": "number",
              "inputType": undefined,
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": "Meses",
                "width": undefined,
                "filter": { type: 'SelectFilter', condition: 'eq', options: "buildColumnValuesFilter" },
                "dataSort": undefined,
                "dataAlign": "right",
                "columnFormat": undefined,
                "exportFormat": undefined,
                "formatExtraData": undefined
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": ["CP", "HE", "Refin"],
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
            "interestRate": {
              "path": "interestRate",
              "label": "Taxa juros",
              "section": "bid",
              "dataType": "number",
              "inputType": undefined,
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": undefined,
                "width": undefined,
                "filter": undefined,
                "dataSort": undefined,
                "dataAlign": "right",
                "columnFormat": "pctColumn",
                "exportFormat": undefined,
                "formatExtraData": undefined
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": ["CP", "HE", "Refin"],
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
            "totalAnnualInterestRate": {
              "path": "totalAnnualInterestRate",
              "label": "C.E.T. anual",
              "section": "bid",
              "dataType": "number",
              "inputType": undefined,
              "inputProps": {
                "domainName": undefined,
                "filteredFrom": undefined,
                "options": undefined,
                "maxValue": undefined,
                "minValue": undefined,
                "maxSize": undefined,
                "minSize": undefined,
                "autoComplete": undefined,
                "mask": undefined,
                "removeMask": undefined
              },
              "tableProps": {
                "viewsTable": undefined,
                "hidden": false,
                "shortLabel": undefined,
                "width": undefined,
                "filter": undefined,
                "dataSort": undefined,
                "dataAlign": "right",
                "columnFormat": "pctColumn",
                "exportFormat": undefined,
                "formatExtraData": undefined
              },
              "relatedFields": undefined,
              "validationMessage": undefined,
              "pattern": undefined,
              "placeholder": undefined,
              "tip": undefined,
              "product": ["CP", "HE", "Refin"],
              "isMandatory": undefined,
              "isReadOnly": undefined,
              "isVisible": undefined,
              "normalize": undefined,
              "extraValidation": undefined,
              "hookOnSave": undefined
            },
          }
        },
      },
      "hasBids": {
        "path": "hasBids",
        "label": "Contenha pelo menos x bid(s)",
        "section": "no-section",
        "dataType": "number",
        "inputType": "select",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [
            { code: "0", description: "1 bid" },
            { code: "1", description: "2 bids" },
            { code: "2", description: "3 bids" },
            { code: "3", description: "4 bids" },
            { code: "4", description: "5 bids" },
            { code: "5", description: "6 bids" },
          ],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": "Bids",
          "width": "30em",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": "bidsSummaryColumn",
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "auctionCreated": {
        "path": "auction.created",
        "label": "Data de Início do Leilão",
        "section": "no-section",
        "dataType": "date",
        "inputType": "date",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "auctionEndDate": {
        "path": "auction.endDate",
        "label": "Data de Término do Leilão",
        "section": "no-section",
        "dataType": "date",
        "inputType": "date",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "paid": {
        "paidDate": {
          "path": "paid.paidDate",
          "label": "Data de Pago",
          "section": "no-section",
          "dataType": "date",
          "inputType": "date",
          "filterType": "range",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": [],
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": "dateColumn",
            "exportFormat": "formatPeriodForExport",
          },
          "relatedFields": undefined,
          "validationMessage": "",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": [],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "supplierInternalName": {
          "path": "paid.supplierInternalName",
          "label": "Parceiro",
          "section": "no-section",
          "dataType": "text",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": [],
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": [],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "paidAmount": {
          "path": "paid.paidAmount",
          "label": "Valor Pago",
          "section": "no-section",
          "dataType": "number",
          "inputType": "number",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": [],
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": 'formatPaidAmount',
            "exportFormat": 'formatPaidAmount'
          },
          "relatedFields": undefined,
          "validationMessage": "",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": [],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
        "installments": {
          "path": "paid.installments",
          "label": "Prestações",
          "section": "no-section",
          "dataType": "number",
          "inputType": "number",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": [],
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": undefined,
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "",
          "pattern": undefined,
          "placeholder": undefined,
          "tip": undefined,
          "product": [],
          "isMandatory": (application) => false,
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        },
      },
      "paidDate": {
        "path": "paid.paidDate",
        "label": "Data de Pago",
        "section": "no-section",
        "dataType": "date",
        "inputType": "date",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": "dateColumn",
          "exportFormat": "formatPeriodForExport",
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "paidAmount": {
        "path": "paid.paidAmount",
        "label": "Valor Pago",
        "section": "no-section",
        "dataType": "number",
        "inputType": "number",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": 'formatPaidAmount',
          "exportFormat": 'formatPaidAmount'
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "serasaScore": {
        "path": "serasaScore",
        "label": "Score Serasa",
        "section": "score",
        "dataType": "number",
        "inputType": "number",
        "filterType": undefined,
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "serasaScoreDate": {
        "path": "serasaScoreDate",
        "label": "Data da Consulta",
        "section": "score",
        "dataType": "date",
        "inputType": "date",
        "filterType": undefined,
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "scores.score": {
        "path": "scores[0].score",
        "label": "FzScore",
        "section": "score",
        "dataType": "number",
        "inputType": "number",
        "filterType": undefined,
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "scores.created": {
        "path": "scores.created",
        "label": "Data da Consulta",
        "section": "score",
        "dataType": "date",
        "inputType": "date",
        "filterType": undefined,
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "createdDays": {
        "path": "createdDays",
        "label": "Dias de criação da proposta",
        "section": undefined,
        "dataType": "number",
        "inputType": "number",
        "filterType": "range",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "closedreason": {
        "path": "closedreason",
        "label": "Motivo de fechamento",
        "section": undefined,
        "dataType": "string",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [
            { "code": ":filter:<not-matching><ep-premium>", "description": ":filter:<not-matching><ep-premium>" },
            { "code": "#apply#:filter:<general-criteria><ep-premium>", "description": "#apply#:filter:<general-criteria><ep-premium>" },
            { "code": "#offers#:paydout:<bulk><bv>", "description": "#offers#:paydout:<bulk><bv>" },
            { "code": ":apply:<ep-std>", "description": ":apply:<ep-std>" },
            { "code": "#apply#:apply:<ep-premium>", "description": "#apply#:apply:<ep-premium>" },
            { "code": ":started:<ep-std>", "description": ":started:<ep-std>" },
            { "code": "#policy#:gaveup:<customer><ep-premium>", "description": "#policy#:gaveup:<customer><ep-premium>" },
            { "code": "#started#:timeout:<timer><ep-premium>", "description": "#started#:timeout:<timer><ep-premium>" },
            { "code": ":timeout:<timer><ep-premium>", "description": ":timeout:<timer><ep-premium>" },
            { "code": "#accepted#:paydout:<portocred><portocred>", "description": "#accepted#:paydout:<portocred><portocred>" },
            { "code": "#accepted#:paydout:<API notification><noverde>", "description": "#accepted#:paydout:<API notification><noverde>" },
            { "code": "#policy#:policy:<ep-std>", "description": "#policy#:policy:<ep-std>" },
            { "code": ":rejected:<no-supplier><ep-premium>", "description": ":rejected:<no-supplier><ep-premium>" },
            { "code": "#accepted#:rejected:<no-supplier><ep-premium>", "description": "#accepted#:rejected:<no-supplier><ep-premium>" },
            { "code": "#accepted#:paydout:<api><cbss>", "description": "#accepted#:paydout:<api><cbss>" },
            { "code": "#accepted#:rejected:<no-supplier><ep-std>", "description": "#accepted#:rejected:<no-supplier><ep-std>" },
            { "code": "#accepted#:paydout:<bulk><bv>", "description": "#accepted#:paydout:<bulk><bv>" },
            { "code": ":apply:<ep-premium>", "description": ":apply:<ep-premium>" },
            { "code": "#policy#:filter:<validation><ep-premium>", "description": "#policy#:filter:<validation><ep-premium>" },
            { "code": "#accepted#:timeout:<timer><ep-std>", "description": "#accepted#:timeout:<timer><ep-std>" },
            { "code": "#policy#:gaveup:<customer><ep-std>", "description": "#policy#:gaveup:<customer><ep-std>" },
            { "code": "#accepted#:gaveup:<customer><ep-premium>", "description": "#accepted#:gaveup:<customer><ep-premium>" },
            { "code": "#started#:rejected:<no-supplier><ep-std>", "description": "#started#:rejected:<no-supplier><ep-std>" },
            { "code": "#apply#:filter:<general-criteria><ep-std>", "description": "#apply#:filter:<general-criteria><ep-std>" },
            { "code": "#policy#:rejected:<no-supplier><ep-std>", "description": "#policy#:rejected:<no-supplier><ep-std>" },
            { "code": ":gaveup:<customer><ep-premium>", "description": ":gaveup:<customer><ep-premium>" },
            { "code": ":rejected:<no-supplier><ep-std>", "description": ":rejected:<no-supplier><ep-std>" },
            { "code": "#accepted#:timeout:<timer><ep-premium>", "description": "#accepted#:timeout:<timer><ep-premium>" },
            { "code": "#policy#:filter:<validation><ep-std>", "description": "#policy#:filter:<validation><ep-std>" },
            { "code": "#apply#:gaveup:<customer><ep-std>", "description": "#apply#:gaveup:<customer><ep-std>" },
            { "code": ":started:<ep-premium>", "description": ":started:<ep-premium>" },
            { "code": "#started#:timeout:<timer><ep-std>", "description": "#started#:timeout:<timer><ep-std>" },
            { "code": "#started#:started:<ep-std>", "description": "#started#:started:<ep-std>" },
            { "code": "#offers#:paydout:<portocred><portocred>", "description": "#offers#:paydout:<portocred><portocred>" },
            { "code": "#policy#:timeout:<timer><ep-std>", "description": "#policy#:timeout:<timer><ep-std>" },
            { "code": ":timeout:<timer><ep-std>", "description": ":timeout:<timer><ep-std>" },
            { "code": "#started#:started:<ep-premium>", "description": "#started#:started:<ep-premium>" },
            { "code": "#policy#:rejected:<no-supplier><ep-premium>", "description": "#policy#:rejected:<no-supplier><ep-premium>" },
            { "code": ":filter:<not-matching><ep-std>", "description": ":filter:<not-matching><ep-std>" },
            { "code": "#policy#:timeout:<timer><ep-premium>", "description": "#policy#:timeout:<timer><ep-premium>" },
            { "code": "#apply#:timeout:<timer><ep-std>", "description": "#apply#:timeout:<timer><ep-std>" },
            { "code": "#accepted#:started:<ep-std>", "description": "#accepted#:started:<ep-std>" },
            { "code": "#apply#:paydout:<><rebel>", "description": "#apply#:paydout:<><rebel>" },
            { "code": ":gaveup:<customer><ep-std>", "description": ":gaveup:<customer><ep-std>" },
            { "code": "#apply#:paydout:<API notification><noverde>", "description": "#apply#:paydout:<API notification><noverde>" },
            { "code": "#accepted#:paydout:<geru><geru>", "description": "#accepted#:paydout:<geru><geru>" },
            { "code": "#accepted#:paydout:<><rebel>", "description": "#accepted#:paydout:<><rebel>" },
            { "code": "#accepted#:gaveup:<customer><ep-std>", "description": "#accepted#:gaveup:<customer><ep-std>" },
            { "code": "#apply#:timeout:<timer><ep-premium>", "description": "#apply#:timeout:<timer><ep-premium>" },
            { "code": "#offers#:paydout:<API notification><noverde>", "description": "#offers#:paydout:<API notification><noverde>" },
            { "code": "#started#:rejected:<no-supplier><ep-premium>", "description": "#started#:rejected:<no-supplier><ep-premium>" },
            { "code": "#started#:gaveup:<customer><ep-premium>", "description": "#started#:gaveup:<customer><ep-premium>" },
            { "code": "#apply#:apply:<ep-std>", "description": "#apply#:apply:<ep-std>" },
            { "code": "#policy#:policy:<ep-premium>", "description": "#policy#:policy:<ep-premium>" },
            { "code": "#started#:gaveup:<customer><ep-std>", "description": "#started#:gaveup:<customer><ep-std>" },
            { "code": "#accepted#:paydout:<bulk><simplic>", "description": "#accepted#:paydout:<bulk><simplic>" },
            { "code": "#apply#:gaveup:<customer><ep-premium>", "description": "#apply#:gaveup:<customer><ep-premium>" },
            { "code": "#accepted#:started:<ep-premium>", "description": "#accepted#:started:<ep-premium>" }
          ],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Produto",
          "width": "8ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Escolha um produto",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "closedreasonregex": {
        "path": "closedreasonregex",
        "label": "Motivo de fechamento (contém|não contém)",
        "section": undefined,
        "dataType": "string",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [
            { "code": "#accepted#", "description": "#accepted#" },
            { "code": "#apply#", "description": "#apply#" },
            { "code": "#offers#", "description": "#offers#" },
            { "code": "#policy#", "description": "#policy#" },
            { "code": "#started#", "description": "#started#" },
            { "code": ":apply:", "description": ":apply:" },
            { "code": ":filter:", "description": ":filter:" },
            { "code": ":gaveup:", "description": ":gaveup:" },
            { "code": ":paydout:", "description": ":paydout:" },
            { "code": ":policy:", "description": ":policy:" },
            { "code": ":rejected:", "description": ":rejected:" },
            { "code": ":started:", "description": ":started:" },
            { "code": ":timeout:", "description": ":timeout:" },
          ],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Produto",
          "width": "8ch",
          "filter": { type: 'TextFilter', delay: 500 },
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "Escolha um produto",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "callbackTime": {
        "path": "callbackTime",
        "label": "Retorno programado",
        "section": "sales",
        "dataType": "date",
        "inputType": "date",
        "filterType": undefined,
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": "relativeTimeColumn",
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "salesLockUntil": {
        "path": "salesLockUntil",
        "label": "Alocado para um agente",
        "section": "sales",
        "dataType": "date",
        "inputType": "date",
        "filterType": undefined,
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": "relativeTimeColumn",
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.greatestAcceptedDate": {
        "path": "extraData.greatestAcceptedDate",
        "label": "Data de aceite",
        "section": "operation-details-not-show",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Aceite da Proposta",
          "width": "12ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.greatestFormalizationCreatedDate": {
        "path": "extraData.greatestFormalizationCreatedDate",
        "label": "Criação da formalização",
        "section": "operation-details-not-show",
        "dataType": "date",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Formalização Criação",
          "width": "15ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.orderPriority": {
        "path": "extraData.orderPriority",
        "label": "Prioridade Peso",
        "section": "operation-details-not-show",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Prioridade Peso",
          "width": "15ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.productPriority": {
        "path": "extraData.productPriority",
        "label": "Prioridade Produto",
        "section": "operation-details-not-show",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Prioridade Prod",
          "width": "14ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.partnerPriority": {
        "path": "extraData.partnerPriority",
        "label": "Prioridade parceiro",
        "section": "operation-details-not-show",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Prioridade Parceiro",
          "width": "12ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.FormState": {
        "path": "extraData.FormState",
        "label": "Status",
        "section": "no-section",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Status",
          "width": "14ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.FormalizationCreated": {
        "path": "extraData.Formalization.Created",
        "label": "Data da Formalização",
        "section": "no-section",
        "dataType": "date",
        "inputType": "date",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Data da Formalização",
          "width": "18ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": "center",
          "columnFormat": "dateColumn",
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.FormalizationEndDate": {
        "path": "extraData.Formalization.ValidUntil",
        "label": "Finaliza Formalização",
        "section": "application",
        "dataType": "date",
        "inputType": "date",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "15ch",
          "filter": undefined,
          "dataSort": false,
          "dataAlign": "right",
          "columnFormat": "relativeTimeColumn",
          "exportFormat": "relativeTimeColumnDoExport",
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "extraData.FormalizationPartner": {
        "path": "extraData.Formalization.SupplierInternalName",
        "label": "Status",
        "section": "no-section",
        "dataType": "string",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": "Parceiro",
          "width": "14ch",
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "deletedReason": {
        "path": "deleted.reason",
        "label": "reason",
        "section": "no-section",
        "dataType": "string",
        "inputType": "text",
        "filterType": "",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "deletedCustomerReason": {
        "path": "deleted.customerReason",
        "label": "customerreason",
        "section": "no-section",
        "dataType": "string",
        "inputType": "text",
        "filterType": "",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "deletedDate": {
        "path": "deleted.date",
        "label": "date",
        "section": "no-section",
        "dataType": "date",
        "inputType": "date",
        "filterType": "",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": [],
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": undefined,
          "hidden": false,
          "shortLabel": undefined,
          "width": undefined,
          "filter": undefined,
          "dataSort": undefined,
          "dataAlign": undefined,
          "columnFormat": undefined,
        },
        "relatedFields": undefined,
        "validationMessage": "",
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": [],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
      "deletedPreviousApplicationState": {
        "path": "deleted.previousApplicationState",
        "label": "previousapplicationstate",
        "section": "no-section",
        "domainName": "application-state",
        "dataType": "number",
        "inputType": "select",
        "filterType": "domain",
        "inputProps": {
          "domainName": "application-state",
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined
        },
        "tableProps": {
          "viewsTable": "application",
          "hidden": false,
          "shortLabel": undefined,
          "width": "17ch",
          "filter": { "type": "SelectDomain" },
          "dataSort": false,
          "dataAlign": "center",
          "columnFormat": "getDescriptionsByDomains",
          "formatExtraData": "application-state",
          "isKey": false,
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": undefined,
        "product": ["CP", "HE", "Refin"],
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined
      },
    },
  }
}

export const flatApplication = (applicationFields) => {
  const fields = Object.keys(applicationFields);
  // removed  __allColumns and __defaultVisibleColumns
  fields.splice(0, 2)
  let section = "";
  const metas = fields.reduce((prev, f) => {
    section = applicationFields[f].section && section !== applicationFields[f].section ? applicationFields[f].section : section;
    if (['vehicleSecurity', "homeSecurity", "trackingData", "bids", "supplierData", "formalization"].indexOf(f) > -1) {
      return [...prev, ...Object.keys(applicationFields[f]).map(securityField => {
        section = applicationFields[f][securityField].section && section !== applicationFields[f][securityField].section ? applicationFields[f][securityField].section : section;
        return { ...applicationFields[f][securityField], section }
      })]
    } else {
      return [...prev, { ...applicationFields[f], section }]
    }
  }, [])

  return metas
}

// flatApplication without removed two position
export const flatApplicationAllPosition = (applicationFields) => {
  const fields = Object.keys(applicationFields);
  let section = "";
  const metas = fields.reduce((prev, f) => {
    section = applicationFields[f].section && section !== applicationFields[f].section ? applicationFields[f].section : section;
    if (['vehicleSecurity', "homeSecurity", "trackingData", "bids", "supplierData", "formalization"].indexOf(f) > -1) {
      return [...prev, ...Object.keys(applicationFields[f]).map(securityField => {
        section = applicationFields[f][securityField].section && section !== applicationFields[f][securityField].section ? applicationFields[f][securityField].section : section;
        return { ...applicationFields[f][securityField], section }
      })]
    } else {
      return [...prev, { ...applicationFields[f], section }]
    }
  }, [])

  return metas
}

export const buildMetasApplicationTable = (applicationFields) => {
  const fields = Object.keys(applicationFields)
  const allMetas = fields.reduce((prev, f) => {
    const innerFields = ['vehicleSecurity', "homeSecurity", "trackingData", "bids", "supplierData"];
    const i = innerFields.indexOf(f)
    if (i > -1) {
      return [...prev, ...Object.keys(applicationFields[f]).map(securityField => {
        return { key: `${innerFields[i]}${securityField}`, ...{ ...applicationFields[f][securityField] } }
      })]
    } else {
      return [...prev, { key: f, ...{ ...applicationFields[f] } }]
    }
  }, [])

  return allMetas
}


export const setBrands = (year, ns) => {
  return api.domains.getVehicleBrands(year).then(res => {
    const brands = res.brands.map(i => {
      return { code: i.description, description: i.description }
    })
    ns.setShared("CACHE.domains.brands", brands)
    return brands
  }).catch(err => {
    console.error("Erro ao tentar se comunicar com a api. modo offline")
  })
}

const ALL_PRODUCTS = 3

export const filterByProduct = (metas, data) => {
  if (data.product) {
    var fieldsToProduct = metas.filter(m => m.product && m.product.indexOf(data.product) > -1)
    return fieldsToProduct
  } else {
    return metas.filter(m => m.product && m.product.length >= ALL_PRODUCTS);
  }
}

export const getMetaPages = (fields, data) => {
  const metas = flatApplication(fields)
  const filteredByProduct = filterByProduct(metas, data)

  const identifier = filteredByProduct.filter(m => m.section === "identifier")
  const personalData = filteredByProduct.filter(m => m.section === "personal-data")
  const residenceData = filteredByProduct.filter(m => m.section === "residence-data")
  const operationDetails = filteredByProduct.filter(m => m.section === "operation-details")
  const vehicleSecurityData = filteredByProduct.filter(m => m.section === "vehicle-security-data")
  const homeSecurityData = filteredByProduct.filter(m => m.section === "home-security-data")
  const dadosWorks = filteredByProduct.filter(m => m.section === "work-information")
  const bankData = filteredByProduct.filter(m => m.section === "bank-data")

  return [
    { page: "1", title: "Identificação do Cliente", groups: [identifier] },
    { page: "2", title: "Datalhes de Operação", groups: [operationDetails, vehicleSecurityData, homeSecurityData] },
    { page: "3", title: "Dados Pessoais", groups: [personalData, residenceData, dadosWorks, bankData] }
  ]
}

export const pathsApplication = (fields) => {
  var paths = Object.keys(fields).reduce((resultSoFar, key) => {
    if (key === 'vehicleSecurity' || key === "homeSecurity" || key === "trackingData" || key === "bids") {
      var innerKeys = Object.keys(fields[key])
      var innerFields = innerKeys.map((innerKey) => fields[key][innerKey])
      return resultSoFar = [...resultSoFar, ...innerFields]
    }
    resultSoFar.push(fields[key])
    return resultSoFar
  }, []).map((it) => it.path)

  return paths
}


export const filterDomains = (filter, options) => {
  return options.filter((item) => item?.products ? item.products.indexOf(filter) > -1 : item.filter.indexOf(filter) > -1);
}

export const getAmountLimits = (application) => {
  switch (application.product) {
    case "Refin":
    case "Fin":
      return {
        "min": 4000,
        "max": 100000
      };
    case "HE":
      return {
        "min": 40000,
        "max": 1000000
      };
    case "Consorcio":
      return {
        "min": 20000,
        "max": 1000000
      };
    default:
      return {
        "min": 500,
        "max": 35000
      };
  }
};

export const getMaxInstallmentLimits = (application) => {
  switch (application.product) {
    case "Consorcio":
      return {
        "min": 50,
        "max": 100000
      };
    default:
      return {
        "min": 0,
        "max": 0
      }
  };
};

export const validateRequestedAmount = (value, application) => {
  let { min, max } = getAmountLimits(application);
  return (value <= max && value >= min)
};


export const validateRequestedaMaxInstallment = (value, application) => {
  let { min, max } = getMaxInstallmentLimits(application);
  return (value <= max && value >= min)
};

export const getDownPaymentLimits = (application) => {
  let security = get(application, "vehicleSecurity.value", 10000);
  let min = security * 0.3;
  let max = security * 0.9;
  return {
    min: min,
    max: max,
    step: 500
  }
};

export const getExistingLoansLimit = (application) => {
  let security = get(application, "vehicleSecurity.value", 10000);
  let min = 500;
  let max = security;
  return {
    min: min,
    max: max,
    step: 500
  }
};

export const getSecurityLimits = (application) => {
  switch (application.product) {
    case "Refin":
    case "Fin":
      return {
        min: 5000,
        max: 100000,
        step: 500
      };

    case "HE":
      return {
        min: 150000,
        max: 9000000,
        step: 1000
      };

    default:
      return {
        min: 500,
        max: 35000,
        step: 500
      };
  }
};

export const getSelectedBid = (application) => application && application.auction && application.auction.bids ? application.auction.bids.filter((bid) => bid.accepted)[0] : null

const colectFields = (object, level, product) => {
  let fieldNames = []
  forOwn(object, (val, key) => {
    if (typeof val === "object") {
      if (!val.path) {
        Array.prototype.push.apply(fieldNames, colectFields(val, level + key + ".", product))
      } else {
        if (!val.product || val.product.test(product)) {
          fieldNames.push(level + key)
        }
      }
    }
  })
  return fieldNames
}

export const validationRG = (value) => {
  if(typeof value === "string" && value !== ''){
    if(application.RGDocType === rg) {
    value = value.replace(/^[0-9A-Z.-]{5,}$/i)
    }

  }
}

export const defaultExpeditorByType = {
  rg: "ssp",
  driver: "detran",
  passport: "pf",
  rne: "pf"
}

const getYears = (isModel) => {
  const d = new Date()
  const currentYear = d.getFullYear() + (isModel ? 1 : 0)
  const list = []
  for (let y = currentYear; y > currentYear - 25; y--) {
    list.push({ code: y, description: y.toString() })
  }
  return list
}

export const modelYear = getYears(true)
export const manufacturingYear = getYears()
export const Application = metaData.appMeta